
import { Badge, FormGroup, Input } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';
import StateInput from 'components/markup/inputs/States';

// import formatText from 'utils/formatText'

const AnalyticsPagesMattersSidebar = ({filter, onSetFilter, onAddArrayItem, onRemoveArrayItem, fields, sidebar}) => {

    const createArrayField = (field, title) => {
        return (
            <ReactSelect
                title={title}
                formGroup={true}
                onChange={(obj) => onAddArrayItem(field, obj)}
                options={fields[field] ? fields[field].map(o => { 
                    const workflow = o.workflow ? fields.workflows.find(w => w._id === o.workflow) : ''
                    const fullName = `${workflow ? workflow.name + ' - ' : ''} ${o.display_name ? o.display_name : o.name}`
    
                    return { 
                        label: fullName, 
                        value: o._id,
                        unformatted: o.display_name ? o.display_name : o.name
                    } 
                }) : []}
                value={null}
                description={filter[field] ? filter[field].map(o => (
                    <Badge key={o.value} color="success d-block text-left mb-2 ml-0 "> 
                        {/* {formatText(o.label).stripHTML()} {' '} */}
                        {o.unformatted}

                        <i 
                            onClick={() => onRemoveArrayItem(field, o)} 
                            className="fas fa-times cursor-pointer pl-3 px-2 float-right" 
                        />
                    </Badge>
                )) : ''}
            />    
        )
    }

    const createTrueOrFalseField = (field, title, isCustomField) => (
        <ReactSelect
            title={title}
            formGroup={true}
            placeholder=" "
            onChange={(obj) => onSetFilter(field, obj.value, isCustomField)}
            options={[
                { label: <span style={{color: 'rgba(0,0,0,.01)'}}>hi</span>, value: null, },
                { label: 'Yes', value: true, },
                { label: 'No', value: false, },
            ]}
            value={isCustomField && filter.custom_fields ? filter.custom_fields[field] : filter[field]}
        />    
    )

    const createNumberField = (field, title, isCustomField) => (
       <div>
            <ReactSelect
                title={title}
                formGroup={true}
                placeholder=" "
                onChange={(obj) => onSetFilter(field + '_type', obj.value, isCustomField)}
                options={[
                    { label: 'Great Than', value: '>', },
                    { label: 'Is Equal To', value: '=', },
                    { label: 'Is Less Than', value: '<', },
                ]}
                value={isCustomField && filter.custom_fields ? filter.custom_fields[field + '_type'] : filter[field + '_type']}
            />    
            <FormGroup>
                <Input 
                    type='number'
                    value={isCustomField && filter.custom_fields ? filter.custom_fields[field] : filter[field]}
                    onChange={(e) => onSetFilter(field, e.target.value, isCustomField)}
                />
            </FormGroup>
       </div>
    )

    const createDropdown = (field, title, options, isCustomField) => (
        <ReactSelect
            title={title}
            formGroup={true}
            placeholder=" "
            onChange={(obj) => onSetFilter(field, obj.value, isCustomField)}
            options={[{ label: <span style={{color: 'rgba(0,0,0,.01)'}}>hi</span>, value: null, }].concat(options)}
            value={isCustomField && filter.custom_fields ? filter.custom_fields[field] : filter[field]}
        />    
    )

    const createTitle = (title) => (
        <div className="archk-query-section">
            <h3 className='text-center text-uppercase text-info'>{title}</h3>
        </div>
    )
    
    return (
    
        <div className="archk-query-sidebar z-depth-1">

            {sidebar.map((section, i) => (
                <div key={i}>
                    {createTitle(section.title)}
                    {section.inputs.map((input, ii) => (
                        <div key={ii} className="archk-query-section">
                            {input.type === 'arrayItem' ? (
                                <span key={ii}>{createArrayField(input.property, input.name)}</span>
                            ) : input.type === 'trueOrFalse' ? (
                                <span key={ii}>{createTrueOrFalseField(input.property, input.name)}</span>
                            ) : input.type === 'number' ? (
                                <span key={ii}>{createNumberField(input.property, input.name)}</span>
                            ) : input.type === 'dropdown' ? (
                                <span key={ii}>{createDropdown(input.property, input.name, input.options)}</span>
                            ) : input.type === 'state' ? (
                                <span key={ii}>
                                    <StateInput 
                                        title={input.name}
                                        placeholder=" "
                                        value={filter[input.property]}
                                        onChange={(state) => onSetFilter(input.property, state)}
                                    />
                                </span>
                            ) : input.type === 'customFields' ? (
                                fields.custom_fields.map((f, iii) => (
                                    <span key={iii}>
                                        {/* type checkbox is not yet accounted for */}
                                        {f.type === 'select' ? (
                                            createDropdown(f._id, f.name, f.answers.map(a => { return { label: a, value: a } }), true)
                                        ) : f.type === 'number' ? (
                                            createNumberField(f._id, f.name, true)
                                        ) : f.type === 'state' ? (
                                            <StateInput 
                                                title={f.name}
                                                value={filter.custom_fields[f._id]}
                                                placeholder=" "
                                                onChange={(state) => onSetFilter(f._id, state, true)}
                                            />
                                        ) : f.type === 'yes-no' ? (
                                            createDropdown(f._id, f.name, [{label: 'Yes', value: 'yes'}, { label: 'No', value: 'no' }], true)
                                        ) : ''}
                                    </span>
                                ))
                            )  : ''}
                        </div>
                    ))}
                </div>
            ))}
        
        </div>

    )
}

export default AnalyticsPagesMattersSidebar