import { Col, Row } from "reactstrap";

import All from './All';
import Categories from './Categories';

const Items = ({setTab, workflow}) => {


    return (

        <>

            <Row>

                <div className="col-auto" style={{width: 300}}>
                   <Categories workflow={workflow} />
                </div>

                <Col className='pl-0'>
                    <All setTab={setTab} workflow={workflow} />
                </Col>

            </Row>

        </>
    )

}

export default Items