// 'VIEW.CAN_VIEW_APP_DASHBOARD' : 'VIEW.CAN_VIEW_APP_DASHBOARD',
// 'VIEW.CAN_VIEW_AFFILIATE_DASHBOARD' : 'VIEW.CAN_VIEW_AFFILIATE_DASHBOARD',
// 'VIEW.CAN_VIEW_ADMIN_DASHBOARD' : 'VIEW.CAN_VIEW_ADMIN_DASHBOARD',

import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesStandard = ({user, setUser, onPrivilegeChange, PRIVILEGES, DOMAINS}) => {

    const [standardPrivileges] = useState([
        PRIVILEGES['VIEW.CAN_VIEW_MANAGEMENT_DASHBOARD'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.TODAYS_ACTIVITY'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.TEXT_THREADS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.USER_ACTIVITY'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.MONTH_TO_DATE'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.PIPELINE_BREAKDOWN'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.REVENUE_PROJECTIONS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.MATTERS_OVERDUE'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.OUTSTANDING_ITEMS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.MATTER_ALERTS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.SIGNATURES'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.SUPPORT_TICKETS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.TASKS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.EVENTS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.DOCUMENTS'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.VOICEMAIL'],
        PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.USER_NOTES'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>MANAGEMENT DASHBOARD</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Privilege 
                privilege={PRIVILEGES['VIEW.CAN_VIEW_MANAGEMENT_DASHBOARD']}
                user={user}
                title={`Can View Management Dashboard - ${DOMAINS.MANAGE}`}
                onPrivilegeChange={onPrivilegeChange}
            />

            <div className="pl-7 pt-3">
                <div className="pl-3">
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.TODAYS_ACTIVITY']}
                        user={user}
                        title={`Can View Todays Activity`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.USER_ACTIVITY']}
                        user={user}
                        title={`Can View User Activity`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.TEXT_THREADS']}
                        user={user}
                        title={`Can View Text Messages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.MONTH_TO_DATE']}
                        user={user}
                        title={`Can View Month To Date Reports`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION']}
                        user={user}
                        title={`Can View Communication Overviews`}
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.UPLOADS']}
                        user={user}
                        title={`Can View Uploads`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.BILLING']}
                        user={user}
                        title={`Can View Billing`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.LEADS']}
                        user={user}
                        title={`Can View Leads`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.HISTORICAL']}
                        user={user}
                        title={`Can View Historical Reports`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.USER_EFFECTIVENESS']}
                        user={user}
                        title={`Can View User Effectiveness Data`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.PROBLEMS']}
                        user={user}
                        title={`Can View Identified Problems`}
                        onPrivilegeChange={onPrivilegeChange}
                    />


                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.PIPELINE_BREAKDOWN']}
                        user={user}
                        title={`Can View Pipeline Breakdown`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.REVENUE_PROJECTIONS']}
                        user={user}
                        title={`Can View Revenue Projections`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.MATTERS_OVERDUE']}
                        user={user}
                        title={`Can View Matters Overdue`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.OUTSTANDING_ITEMS']}
                        user={user}
                        title={`Can View Outstanding Items`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.MATTER_ALERTS']}
                        user={user}
                        title={`Can View Matter Alerts`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.SIGNATURES']}
                        user={user}
                        title={`Can View Signatures Analytics`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS']}
                        user={user}
                        title={`Can View Phone Reports`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.SUPPORT_TICKETS']}
                        user={user}
                        title={`Can View Support Ticket`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.TASKS']}
                        user={user}
                        title={`Can View Tasks`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.EVENTS']}
                        user={user}
                        title={`Can View Events`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.DOCUMENTS']}
                        user={user}
                        title={`Can View Documents`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.VOICEMAIL']}
                        user={user}
                        title={`Can View Voicemail Reports`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MANAGEMENT_DASHBOARD.USER_NOTES']}
                        user={user}
                        title={`Can View User Notes`}
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <hr />

                    <Privilege 
                        privilege={PRIVILEGES['VIEW.MODULE.BK.MANAGEMENT_DASHBOARD']}
                        user={user}
                        title={`Can View BK Module Management Dashboard`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                </div>
            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesStandard);