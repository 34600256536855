import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, FormGroup, Input, Row, Col, Badge } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';
import ObjectFinder from 'components/system/Objects/Finder';

import { toast } from 'react-toastify'

import api from 'api'

const minute = 60
const hour = 3600
const day = 86400

const timeIntervals = [
    { label: 'Immediately', value: 0 },
    { label: '5 Minutes', value: minute * 5 },
    { label: '10 Minutes', value: minute * 10 },
    { label: '15 Minutes', value: minute * 15 },
    { label: '20 Minutes', value: minute * 20 },
    { label: '25 Minutes', value: minute * 25 },
    { label: '30 Minutes', value: minute * 30 },
    { label: '45 Minutes', value: minute * 45 },
    { label: '1 Hour', value: hour * 1 },
    { label: '1.5 Hours', value: hour * 1.5 },
    { label: '2 Hours', value: hour * 2 },
    { label: '3 Hours', value: hour * 3 },
    { label: '4 Hours', value: hour * 4 },
    { label: '5 Hours', value: hour * 5 },
    { label: '6 Hours', value: hour * 6 },
    { label: '7 Hours', value: hour * 7 },
    { label: '8 Hours', value: hour * 8 },
    { label: '9 Hours', value: hour * 9 },
    { label: '10 Hours', value: hour * 10 },
    { label: '12 Hours', value: hour * 12 },
    { label: '16 Hours', value: hour * 16 },
    { label: '24 Hours', value: hour * 24 },
    { label: '2 Days', value: day * 2 },
    { label: '3 Days', value: day * 3 },
    { label: '4 Days', value: day * 4 },
    { label: '5 Days', value: day * 5 },
    { label: '6 Days', value: day * 6 },
    { label: '7 Days', value: day * 7 },
    { label: '10 Days', value: day * 10 },
    { label: '14 Days', value: day * 14 },
    { label: '21 Days', value: day * 21 },
    { label: '28 Days', value: day * 28 },
    { label: '30 Days', value: day * 30 },
    { label: '45 Days', value: day * 45 },
    { label: '60 Days', value: day * 60 },
    { label: '75 Days', value: day * 75 },
    { label: '90 Days', value: day * 90 },
    { label: '180 Days', value: day * 180 },
    { label: '270 Days', value: day * 270 },
    { label: '365 Days', value: day * 365 },

]

const ModalDivisionOverride = ({showModal, toggleModal, workflow, template, onSaved}) => {

    const [checklistName, setChecklistName] = useState('')

    const [state, setState] = useState(template ? template : {
        outstanding_item_categories: [],
        workflow_roles: [],
        checklist: [],
    })

    const onInputChange = useCallback((field, val) => {
        const _state = JSON.parse(JSON.stringify(state))
        _state[field] = val;
        setState(_state)
    }, [state])
    
    const onAddArrayItem = useCallback((field, val) => {
        if(!val) return;

        const _state = JSON.parse(JSON.stringify(state))
        if(_state[field].includes(val)) return;

        _state[field].push(val);
        setState(_state)

        if(field === 'checklist') setChecklistName('')
    }, [state])

    const onRemoveArrayItem = useCallback((field, val) => {
        const _state = JSON.parse(JSON.stringify(state))
        _state[field] = _state[field].filter(f => f !== val)
        setState(_state)
    }, [state])

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') onAddArrayItem('checklist', checklistName);
	}, [checklistName, onAddArrayItem])

    const onSave = useCallback(async () => {
        if(!state.name) return toast.info(`A name must be set for this item.`)

        let action;

        if(template) {
            action = await api.outstanding_item_templates.update(template._id, state)
        } else {
            action = await api.outstanding_item_templates.create({ ...state, workflow: workflow._id })
        }

        if(!action.success) return toast.error(`Could not save template at this time.`)

        onSaved(action.data)
        toggleModal()

    }, [onSaved, state, template, toggleModal, workflow._id])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">{template ? 'Edit Template' : 'Add Template'}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <h2 className='text-uppercase'>Template Visibility</h2>

                <Row>
                    <Col md={7}>
                        <FormGroup>
                            <label className='form-control-label'>Item Name*</label>
                            <Input 
                                type="text"
                                value={state.name}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        

                        <ReactSelect
                            title={"Priority"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onInputChange('priority', obj.value)}
                            options={[
                                { label: 'Priority 1 (Highest)', value: 1 },
                                { label: 'Priority 2 (High)', value: 2 },
                                { label: 'Priority 3 (Default)', value: 3 },
                                { label: 'Priority 4 (Low)', value: 4 },
                                { label: 'Priority 5 (Lowest)', value: 5 },
                            ]}
                            value={state.priority}
                        />    
                    </Col>

                    <Col md={5}>
                        <FormGroup>
                            <label className='form-control-label'>Item Description</label>
                            <Input 
                                style={{height: 93}}
                                type="textarea"
                                value={state.description}
                                onChange={e => onInputChange('description', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                

            </div>

            <div className='modal-body bg-secondary'>

                <h2 className='text-uppercase'>Item Dates & Times</h2>

                <Row>
                    <Col md={6}>
                        <ReactSelect
                            title={"Due Date"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onInputChange('due_date', obj.value)}
                            options={timeIntervals}
                            value={state.due_date}
                        />    

                    </Col>
                    <Col md={6}>
                        <ReactSelect
                            title={"Follow Up Date"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onInputChange('next_follow_up_at', obj.value)}
                            options={timeIntervals}
                            value={state.next_follow_up_at}
                        />    

                    </Col>
                </Row>

                <ReactSelect
                    title={"Follow Up Interval"}
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('follow_up_interval', obj.value)}
                    options={[
                        { label: 'Only Follow Up On Above Date', value: '0' },
                        { label: '1st Of Month', value: '1st_of_the_month' },
                        { label: 'Every Day', value: '1' },
                        { label: 'Every 2 Days', value: '2' },
                        { label: 'Every 3 Days', value: '3' },
                        { label: 'Every 4 Days', value: '4' },
                        { label: 'Every 5 Days', value: '5' },
                        { label: 'Every 6 Days', value: '6' },
                        { label: 'Every Week', value: '7' },
                        { label: 'Every 2 Weeks', value: '14' },
                        { label: 'Every 3 Weeks', value: '21' },
                        { label: 'Every 4 Weeks', value: '28' },
                        { label: 'Every 45 Days', value: '45' },
                        { label: 'Every 60 Days', value: '60' },
                        { label: 'Every 90 Days', value: '90' },
                        { label: 'Every 120 Days', value: '120' },
                        { label: 'Every 150 Days', value: '150' },
                        { label: 'Every 180 Days', value: '180' },
                        { label: 'Every 365 Days', value: '365' },
                    ]}
                    value={state.follow_up_interval}
                />    

            </div>

            <div className='modal-body'>

                <h2 className='text-uppercase'>Assignments & Checklist</h2>

                <Row>
                    <Col md={6}>

                        <SearchCollections
                            collection="workflow_roles" 
                            title="Assigned To"
                            value={null}
                            onChange={(obj) => onAddArrayItem('workflow_roles', obj.value)}
                            filter={{
                                workflow: workflow._id
                            }}
                        /> 

                        {state.workflow_roles.map((r) => (
                            <Badge key={r} color="info">
                                <ObjectFinder collection="workflow_roles" _id={r} />
                                <i onClick={() => onRemoveArrayItem('workflow_roles', r)} className="fas fa-trash pl-3 cursor-pointer" />
                            </Badge>
                        ))}

                    </Col>

                    <Col md={6}>

                        <FormGroup>
                            <label className='form-control-label'>Checklist Item Name (Press Enter To Add)</label>
                            <Input 
                                type="text"
                                value={checklistName}
                                onChange={e => setChecklistName(e.target.value)}
                                onKeyDown={_handleKeyDown}
                            />
                        </FormGroup>


                        {state.checklist.map((r, i) => (
                            <Badge key={i} color="success">
                                {r}
                                <i onClick={() => onRemoveArrayItem('checklist', r)} className="fas fa-trash pl-3 cursor-pointer" />
                            </Badge>
                        ))}

                    </Col>
                </Row>

                <SearchCollections
                    collection="outstanding_item_categories" 
                    title="Categories"
                    value={null}
                    onChange={(obj) => onAddArrayItem('outstanding_item_categories', obj.value)}
                    filter={{
                        workflow: workflow._id
                    }}
                /> 

                {state.outstanding_item_categories.map((r) => (
                    <Badge key={r} color="purple">
                        <ObjectFinder collection="outstanding_item_categories" _id={r} />
                        <i onClick={() => onRemoveArrayItem('outstanding_item_categories', r)} className="fas fa-trash pl-3 cursor-pointer" />
                    </Badge>
                ))}

            </div>


            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Save Template
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);