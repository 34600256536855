import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, CardBody, Badge, Row, Col } from "reactstrap";

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";


import { toggleAlertBS } from "store/functions/system/system";
import ObjectFinder from 'components/system/Objects/Finder';
import { getUrlParameter } from "utils/urls";

import { toast } from 'react-toastify'

import Edit from './Edit'



const TasksAll = ({setTab, workflow}) => {

    const [templates, setTemplates] = useState(null)
    const [toDelete, setToDelete] = useState(null)

    const onSaved = useCallback((template) => {
        const state = JSON.parse(JSON.stringify(templates))
        const index = state.findIndex(e => e._id === template._id)

        if(index !== -1) {
            state[index] = template
        } else {
            state.unshift(template)
        }
        setTemplates(state)
    }, [templates])

    const fetchData = useCallback(async () => {
        const data = await api.outstanding_item_templates.find(workflow._id)
        if(!data.success) return toast.info(`Could not load templates at this time.`)

        setTemplates(data.data)
    }, [workflow._id])

    const onDeleteTask = useCallback(async () => {
        const result = await api.outstanding_item_templates.delete(toDelete._id)
        if(!result.success) return toast.error(`Could not delete template at this moment.`)

        let state = JSON.parse(JSON.stringify(templates))
        state = state.filter(s => s._id !== toDelete._id)
        setTemplates(state)

    }, [templates, toDelete])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!templates) return <div><Circle /></div>

    return (

        <>





            <Card className="card-color card-primary table-fixed">
                <CardHeader>
                    <Row>
                        <Col md={6} className="align-self-center">
                            <CardTitle className="mb-0">Item Templates</CardTitle>
                        </Col>
                        <Col md={6} className="align-self-center text-right">
                            <ModalToggler component={Edit} workflow={workflow} onSaved={onSaved}>
                                <button className="btn btn-success">Add Template <i className="fas fa-plus ml-2" /></button>
                            </ModalToggler>
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Categories</th>
                                <th>Checklist</th>
                                <th>Follow Up Interval</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {templates.length ? templates.map(t => (
                                <tr key={t._id}>
                                    <td>{t.name}</td>

                                    <td>
                                        {t.outstanding_item_categories.map((r) => (
                                            <Badge key={r} color="purple">
                                                <ObjectFinder collection="outstanding_item_categories" _id={r} />
                                            </Badge>
                                        ))}
                                    </td>

                                    <td>{t.checklist.length}</td>

                                    <td>
                                        {t.follow_up_interval === '0' ? '' : t.follow_up_interval === '1st_of_the_month' ? '1st Of Month' : `Every ${t.follow_up_interval} Days`}
                                    </td>

                                    <td>
                                        <div className="text-right">
                                            <TableIcons
                                                icons={[
                                                    { 
                                                        icon: 'fas fa-edit',
                                                        color: 'success', 
                                                        wrapper: ModalToggler, 
                                                        component: Edit,
                                                        onSaved: onSaved,
                                                        workflow: workflow,
                                                        template: t
                                                    },
                                                    {  
                                                        icon: 'fas fa-trash', 
                                                        color: 'danger',
                                                        onClick: () => setToDelete(t)
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No Templates Found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Card>
            
            <ConfirmationModal 
                showModal={toDelete ? true : false}
                toggleModal={() => setToDelete(null)}
                title="Delete Task"
                body={(
                    <span>
                        Are you sure you wish to delete the task {toDelete ? toDelete.name : ''}?
                    </span>
                )}
                onConfirmation={onDeleteTask}
            />

        </>
    )

}


const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(TasksAll);