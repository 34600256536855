import IntuideskSection from "components/markup/layout/IntuideskSection";

import Overview from './Overview'
import LeadSources from './LeadSources'
import LeadSourceCategories from './LeadSourceCategories'
import Leads from './Leads'
import Breakdown from './Breakdown'
import Daily from './Daily'

const MarketingView = ({match}) => (
    <IntuideskSection 
        title="Marketing"
        section="marketing"
        defaultRoute=""
        match={match}
        pages={[
            { value: '', icon: 'fas fa-chart-line', name: 'Daily', Component: Daily, extraProps: {range: 30} },
            
            { value: 'breakdown_30', icon: 'fas fa-chart-line', name: 'Breakdown', Component: Breakdown, extraProps: {range: 30} },
            { value: 'breakdown_90', icon: 'fas fa-chart-line', name: 'Breakdown', Component: Breakdown, extraProps: {range: 90}, hidden: true },
            { value: 'breakdown_180', icon: 'fas fa-chart-line', name: 'Breakdown', Component: Breakdown, extraProps: {range: 180}, hidden: true },
            { value: 'breakdown_365', icon: 'fas fa-chart-line', name: 'Breakdown', Component: Breakdown, extraProps: {range: 365}, hidden: true },

            { value: 'overview_30', icon: 'fas fa-chart-line', name: 'Overview', Component: Overview, extraProps: {range: 30}, },
            { value: 'overview_90', icon: 'fas fa-chart-line', name: 'Overview', Component: Overview, extraProps: {range: 90}, hidden: true },
            { value: 'overview_180', icon: 'fas fa-chart-line', name: 'Overview', Component: Overview, extraProps: {range: 180}, hidden: true },
            { value: 'overview_365', icon: 'fas fa-chart-line', name: 'Overview', Component: Overview, extraProps: {range: 365}, hidden: true },
            // { value: 'days_180', icon: 'fas fa-chart-line', name: '180 Days', Component: Overview, extraProps: {range: 180} },
            // { value: 'days_365', icon: 'fas fa-chart-line', name: '365 Days', Component: Overview, extraProps: {range: 365} },
            { value: 'lead_sources', icon: 'fa-solid fa-chart-simple', name: 'Lead Sources', Component: LeadSources },
            { value: 'leads', icon: 'fas fa-users', name: 'Leads', Component: Leads },
            { value: 'lead_source_categories', icon: 'fa-solid fa-chart-simple', name: 'Lead Sources', Component: LeadSourceCategories, hidden: true },
            // { value: 'analysis', icon: 'fa-solid fa-chart-pie', name: 'Source Analysis', Component: SourceAnalysis },
            // { value: 'predictions', icon: 'fa-solid fa-comments-dollar', name: 'Predictions', Component: Predictions },
        ]}
    />
)

export default MarketingView;