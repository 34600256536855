import { useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,  Input } from "reactstrap";
import { connect } from 'react-redux';

import { setSelectedDivision } from 'store/functions/state'
import { getSoftRefreshLink } from 'utils/urls'
import A from 'components/markup/links/A'

import Search from './Search';

import hasPrivilege from 'utils/hasPrivilege'

const NavbarDesktop = ({divisions, selected_division, company, PLATFORM, DOMAINS}) => {
    const location = useLocation();

    const [redirect, setRedirect] = useState('');

    const isActive = (section) => {
        try {
            const nav1 = location.pathname.split('/')[1]
            return nav1 === section ? true : false;
        } catch(e) {}
    }

    const onSetDivision = useCallback((division) => {
        setRedirect(getSoftRefreshLink())
        setSelectedDivision(division._id)
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (

        <div id="desktop-navbar-top" className="bg-secondary z-depth-1">

            <Container  fluid>

                <div className="header mb-0">

                    <div className="header-body">
                        <Row className="align-items-center py-2">

                            <Col xs="4" >
                                <ul className="navbar-icons">

                                    <li>
                                        <h2 className="dis mb-0">
                                            <img src={PLATFORM.FAVICON} style={{width: 20, position: 'relative', top: -2}} alt="Intuidesk" className="mr-3"/>
                                            {company.name}
                                        </h2>
                                    </li>
                                
                                </ul>
                            </Col>

                            <Col xs="4" >
                                {hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING') || hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') || hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') ? (
                                    <Search />
                                ) : ''}
                                
                            </Col>

                            <Col xs="4"  className="text-right header-right">

                                <Nav className="align-items-center ml-auto ml-md-0 pl-5 "  navbar>
                                    <UncontrolledDropdown nav>
                                        <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                            {selected_division.name ? selected_division.name : "NO DIVISION SELECTED"} <i className="fas fa-caret-down ml-2 px-0 " />
                                        </DropdownToggle>

                                        <DropdownMenu end>
                                            <div className="dropdown-content">
                                                <DropdownItem  className="noti-title" header tag="div">
                                                    <h6 className="text-overflow m-0">Selected Division</h6>
                                                </DropdownItem>

                                                {divisions.map(division => (
                                                    <DropdownItem key={division._id} onClick={() => onSetDivision(division)} >
                                                        {division.name}
                                                    </DropdownItem>
                                                ))}
                                            </div>
                                        </DropdownMenu>

                                    </UncontrolledDropdown>

                                    {hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING') || hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') ? (
                                        <UncontrolledDropdown nav>
                                            <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                                <i className="fas fa-cogs text-muted" />
                                            </DropdownToggle>

                                            <DropdownMenu end>
                                                <div className="dropdown-content">

                                                    {hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING') ? (
                                                        <DropdownItem tag={Link} to="/staffing" >
                                                            <i className=" fas fa-users" />
                                                            <span>Staff / Users</span>
                                                        </DropdownItem>
                                                    ) : ''}
                                                    
                                                    {hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') ? (
                                                        <DropdownItem tag={Link} to="/company/edit/names" >
                                                            <i className="fas fa-cogs" />
                                                            <span>Company</span>
                                                        </DropdownItem>
                                                    ) : ''}
                                                    
                                                </div>
                                            </DropdownMenu>

                                        </UncontrolledDropdown>
                                    ) : ''}


                                    <UncontrolledDropdown nav>
                                        <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                            <i className="fas fa-door-open text-muted" />
                                        </DropdownToggle>

                                        <DropdownMenu end>
                                            <div className="dropdown-content">

                                                <A href={DOMAINS.APP}>
                                                    <DropdownItem  className='pb-2 pt-3'>
                                                        <i className=" fas fa-database" />
                                                        <span>Dashboard</span>
                                                    </DropdownItem>
                                                </A>
                                                
                                                <DropdownItem tag={Link} to="/custom_views" >
                                                    <i className=" fa-solid fa-street-view" />
                                                    <span>Custom Views</span>
                                                </DropdownItem>


                                                    <DropdownItem  href={DOMAINS.AUTH + '/logout'} className='pb-3 pt-3'>
                                                        <i className="ni ni-user-run" />
                                                        <span>Logout</span>
                                                    </DropdownItem>



                                            </div>
                                        </DropdownMenu>

                                    </UncontrolledDropdown>

                                </Nav>
                            </Col>

                        </Row>
                    </div>

                </div>

            </Container>

            <div className="border-bottom border-top bg-white header-bottom">
                <Container fluid>
                    <Nav className="align-items-center"  navbar>

                        <ul>
                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.OVERVIEW') ? (
                                <li className={location.pathname === '/' || isActive('overview') ? 'active' : ''}>
                                    <Link to="/">
                                        {location.pathname === '/' || location.pathname.includes('/overview')  ? <i className="fas fa-chart-line mr-2 " />  : ''}
                                        Overview
                                    </Link>
                                </li>
                            ) : ''}
                            
                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING') ? (
                                <li className={isActive('marketing') ? 'active' : ''}>
                                    <Link to="/marketing">
                                        {isActive('marketing') ? <i className="fas fa-mail-bulk mr-2 " />  : ''}
                                        Marketing
                                    </Link>
                                </li>
                            ) : ''}
                          
                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.ANALYZE') ? (
                                <li className={isActive('analyze') ? 'active' : ''}>
                                    <Link to="/analyze">
                                        {isActive('analyze') ? <i className="fas fa-chart-pie mr-1 " />  : ''}
                                        Analyze
                                    </Link>
                                </li>
                            ) : ''}

                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.COMMUNICATE') ? (
                                <li className={isActive('communication') ? 'active' : ''}>
                                    <Link to="/communication">
                                        {isActive('communication') ? <i className="fas fa-headset mr-2 " />  : ''}
                                        Communicate
                                    </Link>
                                </li>
                            ) : ''}

                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.AUTOMATE') ? (
                                <li className={isActive('automations') ? 'active' : ''}>
                                    <Link to="/automations">
                                        {isActive('automations') ? <i className="fas fa-bolt mr-2 " />  : ''}
                                        Automate
                                    </Link>
                                </li>
                            ) : ''}

                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING') ? (
                                <li className={isActive('division_users') ? 'active' : ''}>
                                    <Link to="/division_users">
                                        {isActive('division_users') ? <i className="fas fa-users mr-2 " />  : ''}
                                        Staffing
                                    </Link>
                                </li>
                            ) : ''}

                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.SYSTEM') ? (
                                <li className={isActive('system') ? 'active' : ''}>
                                    <Link to="/system">
                                        {isActive('system') ? <i className="fas fa-laptop mr-2 " />  : ''}
                                        System
                                    </Link>
                                </li>
                            ) : ''}
                           
                            {hasPrivilege('VIEW.ADMIN_DASHBOARD.LAYOUT') ? (
                                <li className={isActive('layout') ? 'active' : ''}>
                                    <Link to="/layout">
                                        {isActive('layout') ? <i className="fa-solid fa-layer-group mr-2 " />  : ''}
                                        Layout
                                    </Link>
                                </li>
                            ) : ''}
                            
                            {hasPrivilege('VIEW.AI') ? (
                                <li className={isActive('ai') ? 'active' : ''}>
                                    <Link to="/ai">
                                        {isActive('ai') ? <i className="fas fa-lightbulb mr-2 " />  : ''}
                                        AI
                                    </Link>
                                </li>
                            ) : ''}
                            
                            {hasPrivilege('VIEW.AI') ? (
                                <li className={isActive('developers') ? 'active' : ''}>
                                    <Link to="/developers">
                                        {isActive('developers') ? <i className="fas fa-database mr-2 " />  : ''}
                                        Developers
                                    </Link>
                                </li>
                            ) : ''}

                        </ul>
                    </Nav>    
                </Container>           
            </div>

        </div>

    )

}

const mapStateToProps = state => {
	return {
	    company: state.company,
	    divisions: state.divisions,
	    selected_division: state.state.selected_division,
	    PLATFORM: state.load.PLATFORM,
	    DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(NavbarDesktop);
