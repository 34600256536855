import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Progress, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import BarLine from 'components/charts/BarLine';
import PieArea from 'components/charts/PieArea';
import { useCallback, useEffect, useState } from 'react';

import ApiError from 'components/markup/layout/ApiError';
import Circle from 'components/markup/loading/Circle'
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api'
import moment from 'moment';

import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';
import { getUrlParameter } from 'utils/urls';


const MarketingOverview = ({range, selected_division}) => {

    const lead_source = getUrlParameter('lead_source')
    const location = useLocation()
    const pathname = location.pathname

    const [data, setData] = useState(null)
    const [err, setErr] = useState(null)

    const renderNumber = useCallback((number) => {
        if(!number) return '$0.00'
        return formatCurrency(number)
    }, [])
   
    const renderPercentChange = useCallback((currentNumber, pastNumber, reverse) => {
        if(!pastNumber) return <span className='text-muted'>+NA%</span>
        if(!currentNumber && !pastNumber) return <span className='text-muted'>+0%</span>
        if(currentNumber && !pastNumber) return <span className='text-muted'>+NA%</span>
        
        let number = parseFloat(((currentNumber / pastNumber) - 1).toFixed(4));
        number = number * 100
        number = parseFloat(number.toFixed(2))

        return number < 0 ? <span className={reverse ? 'text-success' : 'text-danger'}>{number}%</span> : 
            <span className={reverse ? 'text-danger' : 'text-success'}>+{number}%</span>
    }, [])

    const getBarPercent = useCallback((num1, num2) => {
        if(!num2) return 0
        let num = parseFloat(((num1 / num2) * 100).toFixed(2))
        if(num > 100) num = 100;
        return num
    }, [])

    const fetchData = useCallback(async () => {
        setData(null)
        let type = 
            pathname.includes(30) ? 30 : 
            pathname.includes(60) ? 60 : 
            pathname.includes(90) ? 90 : 
            pathname.includes(180) ? 180 : 
            pathname.includes(365) ? 365 : 
            30;
        
        let date = 
            type === 30 ? parseInt(moment().endOf('day').format('X')) :
            type === 60 ? parseInt(moment().endOf('day').format('X')) :
            type === 90 ? parseInt(moment().endOf('week').format('X')) :
            type === 180 ? parseInt(moment().endOf('month').add(1, 'seconds').format('X')) :
            parseInt(moment().endOf('month').add(1, 'seconds').format('X'))

        const _data = await api.marketing.overview({
            division: selected_division._id,
            type: type,
            date: date,
            lead_source: lead_source ? lead_source : undefined
            // lead_source: '669ab388bae27e55d73472a8'
        })

        if(!_data.success) return setErr(_data.message ? _data.message : 'Server Error')
        setData(_data.data)
    }, [lead_source, pathname, selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError error={err} />
    if(!data) return <div className='py-6'><Circle /></div>

    return (

        <>

        {lead_source ? (
            <Card>
                <CardBody>
                    <h2 className='text-center display-2 my-0'>
                    <ObjectFinder collection="lead_sources" _id={lead_source} />
                    </h2>
                </CardBody>
            </Card>
        ) : ''}

        <Nav
            className="nav-fill flex-column flex-sm-row"
            id="tabs-text"
            pills
            role="tablist"
        >

            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${pathname === '/marketing/overview_30' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/overview_30"
                >
                    Last 30 Days
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${ pathname === '/marketing/overview_90' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/marketing/overview_90"
                >
                    Last 90 Days
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${ pathname === '/marketing/overview_180' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/marketing/overview_180"
                >
                    Last 180 Days
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${ pathname === '/marketing/overview_365' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/marketing/overview_365"
                >
                    Last 365 Days
                </NavLink>
            </NavItem>

        </Nav>


        <Row>

            <Col md={12}>
                <BarLine 
                    type="line"
                    colors={['orange', 'purple', 'teal', 'green']}
                    title={`Marketing Results Last ${range} Days`}
                    labels={data.timeSeries.labels}
                    datasets={data.timeSeries.datasets}
                />

                <Row>
                    <Col md={4}>
                        <Card className='custom-shadow-1 border'>
                            <CardHeader className='py-4'>
                            <h2 className=" mb-0">
                                {lead_source ? (
                                    <ObjectFinder collection="lead_sources" _id={lead_source} />
                                ) : (
                                    <ObjectFinder collection="divisions" _id={selected_division._id} />     
                                )}
                                {' '}
                                - Sales Funnel
                            </h2>

                            </CardHeader>

                            <CardBody>
                                <div className="progress-wrapper pt-0">
                                    <div className={`progress-info mb-0 mt--2`}>
                                        <div className="progress-label">
                                            <span className={`bg-white font-weight-bold text-sm ml--4 text-warning`}>
                                                Leads
                                            </span>
                                        </div>
                                        <div className="progress-percentage">
                                            <span>{data.current.leads} Total - {data.current.leads ? 100 : 0}%</span>
                                        </div>
                                    </div>
                                    <Progress max={data.current.leads} value={data.current.leads} color="warning" />
                                </div>

                                <div className="progress-wrapper pt-0">
                                    <div className={`progress-info mb-0 mt--2`}>
                                        <div className="progress-label">
                                            <span className={`bg-white font-weight-bold text-sm ml--4 text-purple`}>
                                                Opportunities
                                            </span>
                                        </div>
                                        <div className="progress-percentage">
                                            <span>{data.current.opportunities} Total - {getBarPercent(data.current.opportunities, data.current.leads)}%</span>
                                        </div>
                                    </div>
                                    <Progress max={100} value={getBarPercent(data.current.opportunities, data.current.leads)} color="purple" />
                                </div>
                                
                                <div className="progress-wrapper pt-0">
                                    <div className={`progress-info mb-0 mt--2`}>
                                        <div className="progress-label">
                                            <span className={`bg-white font-weight-bold text-sm ml--4 text-info`}>
                                                Hired
                                            </span>
                                        </div>
                                        <div className="progress-percentage">
                                            <span>{data.current.hires} Total - {getBarPercent(data.current.hires, data.current.opportunities)}%</span>
                                        </div>
                                    </div>
                                    <Progress max={100} value={getBarPercent(data.current.hires, data.current.opportunities)} color="info" />
                                </div>

                                <div className="progress-wrapper pt-0">
                                    <div className={`progress-info mb-0 mt--2`}>
                                        <div className="progress-label">
                                            <span className={`bg-white font-weight-bold text-sm ml--4 text-success`}>
                                                Won
                                            </span>
                                        </div>
                                        <div className="progress-percentage">
                                            <span>{data.current.won} Total - {getBarPercent(data.current.won, data.current.opportunities)}%</span>
                                        </div>
                                    </div>
                                    <Progress max={100} value={getBarPercent(data.current.won, data.current.opportunities)} color="success" />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={8}>
                        <Row>
                            <Col md={lead_source ? 6 : 4}>
                                <PieArea 
                                    small={true}
                                    type="pie"
                                    colors={['purple', 'teal', 'green']}
                                    title="Opportunities / Hired / Won"
                                    labels={['Opportunities', 'Hired', "Won"]}
                                    dataset={[data.current.opportunities, data.current.hires , data.current.won]}
                                />
                            </Col>

                            {!lead_source ? (
                                <Col md={4}>
                                    <PieArea 
                                        small={true}
                                        type="pie"
                                        colors={['green', 'red']}
                                        title="Attributed / Unknown"
                                        labels={['Attributed', 'Unknown']}
                                        dataset={[data.current.mattersWithSource ,data.current.opportunities - data.current.mattersWithSource]}
                                    />
                                </Col>
                            ) : ''}

                            <Col md={lead_source ? 6 : 4}>
                                <PieArea 
                                    small={true}
                                    type="pie"
                                    colors={['green', 'red']}
                                    title="Wanted / Unwanted"
                                    labels={['Wanted', 'Unwanted']}
                                    dataset={[data.current.potential + data.current.hires + data.current.won, data.current.dnq + data.current.noHire + data.current.referOut]}
                                />
                            </Col>
                        </Row>
                    </Col>

                </Row>

            
                <Row>

                    <Col md={6}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Time Till Hired</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>
                                    {formatText(data.current.timeTillHired).secondsToTime()} 
                                    <small><small className='pl-4'><span className={data.current.timeTillHired > data.past.timeTillHired ? 'text-danger' : 'text-success'}>{data.current.timeTillHired > data.past.timeTillHired ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />}</span> </small></small>
                                </h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{formatText(data.past.timeTillHired).secondsToTime()}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Time Till Won</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>
                                    {formatText(data.current.timeTillWon).secondsToTime()}
                                    <small><small className='pl-4'><span className={data.current.timeTillWon > data.past.timeTillWon ? 'text-danger' : 'text-success'}>{data.current.timeTillWon > data.past.timeTillWon ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />}</span> </small></small>

                                </h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{formatText(data.past.timeTillWon).secondsToTime()}</b>
                            </CardFooter>
                        </Card>
                    </Col>

                        
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Leads</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.leads} <small><small className='pl-4'>{renderPercentChange(data.current.leads, data.past.leads)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.leads}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Opportunities</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.opportunities} <small><small className='pl-4'>{renderPercentChange(data.current.opportunities, data.past.opportunities)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.leads}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Potential</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.potential} <small><small className='pl-4'>{renderPercentChange(data.current.potential, data.past.potential)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.potential}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Hires</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.hires} <small><small className='pl-4'>{renderPercentChange(data.current.hires, data.past.hires)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.hires}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Won</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.won} <small><small className='pl-4'>{renderPercentChange(data.current.won, data.past.won)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.won}</b>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total DNQ</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.dnq} <small><small className='pl-4'>{renderPercentChange(data.current.dnq, data.past.dnq, true)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.dnq}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total No Hire</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.noHire} <small><small className='pl-4'>{renderPercentChange(data.current.noHire, data.past.noHire, true)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.noHire}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Refer Out</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.referOut} <small><small className='pl-4'>{renderPercentChange(data.current.referOut, data.past.referOut, true)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.referOut}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    

                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Estimated Value</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.estimatedValue)} <small><small className='pl-4'>{renderPercentChange(data.current.estimatedValue, data.past.estimatedValue)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.estimatedValue)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Actual Value</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.billingTotal)} <small><small className='pl-4'>{renderPercentChange(data.current.billingTotal, data.past.billingTotal)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.billingTotal)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Amount Spent</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.cost)} <small><small className='pl-4'>{renderPercentChange(data.current.cost, data.past.cost)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.cost)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Hired Value</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.avgHiredValue)} <small><small className='pl-4'>{renderPercentChange(data.current.avgHiredValue, data.past.avgHiredValue)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.avgHiredValue)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Paid On Matter</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.billingPaid)} <small><small className='pl-4'>{renderPercentChange(data.current.billingPaid, data.past.billingPaid)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.billingPaid)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Cost Per Lead</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.costPerLead)} <small><small className='pl-4'>{renderPercentChange(data.current.costPerLead, data.past.costPerLead, true)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.costPerLead)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Cost Per Acquisition</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.costPerAcquisition)} <small><small className='pl-4'>{renderPercentChange(data.current.costPerAcquisition, data.past.costPerAcquisition, true)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.costPerAcquisition)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Cost Per Win</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.costPerWin)} <small><small className='pl-4'>{renderPercentChange(data.current.costPerWin, data.past.costPerWin, true)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{renderNumber(data.past.costPerWin)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                
                    {!lead_source ? (
                        <Col md={3}>
                            <Card className='custom-shadow-1 text-center'>
                                <CardHeader>
                                    <CardTitle className="mb-0">Contributing Sources</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h2 className='display-4 mb-0'>{data.current.contributingSources} <small><small className='pl-4'>{renderPercentChange(data.current.contributingSources, data.past.contributingSources)}</small></small></h2>
                                </CardBody>
                                <CardFooter>
                                    Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.contributingSources}</b>
                                </CardFooter>
                            </Card>
                        </Col>
                    ) : ''}
                    
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Leads In Business Hours</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.inBusinessHours} <small><small className='pl-4'>{renderPercentChange(data.current.inBusinessHours, data.past.inBusinessHours)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.inBusinessHours}</b>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Leads Outside Business Hours</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.leads - data.current.inBusinessHours} <small><small className='pl-4'>{renderPercentChange(data.current.leads - data.current.inBusinessHours, data.past.leads - data.past.inBusinessHours, true)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Prior {range} Days: <b className='text-dark font-weight-bold '>{data.past.leads - data.past.inBusinessHours}</b>
                            </CardFooter>
                        </Card>
                    </Col>

                
                    {/* <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Best Performing Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>TLS PPC</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$253</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Worst Performing Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>LSAs</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$608</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Highest Paid Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>LSAs</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$608</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='custom-shadow-1 text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Lowest Paid Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>LSAs</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$608</b>
                            </CardFooter>
                        </Card>
                    </Col> */}

                </Row>
            </Col>

            {/* <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Source Breakdown"
                    labels={['TLS', 'Google', 'Facebook', "Unknown"]}
                    dataset={[12, 19, 3, 5]}
                />

                <PieArea 
                    type="pie"
                    title="Hired Breakdown"
                    labels={['TLS', 'Google', 'Facebook', "Unknown"]}
                    dataset={[486, 240, 194, 600]}
                />

                <PieArea 
                    type="pie"
                    title="Won Breakdown"
                    labels={['TLS', 'Google', 'Facebook', "Unknown"]}
                    dataset={[486, 240, 194, 600]}
                />
            </Col> */}


        </Row>


      
    </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MarketingOverview);