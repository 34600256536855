import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Progress, Row, CardTitle, Nav, NavItem, NavLink, CardFooter } from "reactstrap";

import BarLine from 'components/charts/BarLine';
import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import ApiError from 'components/markup/layout/ApiError';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import moment from 'moment';

import formatText from 'utils/formatText';
import { getUrlParameter } from 'utils/urls';
import PieArea from 'components/charts/PieArea';
import DatePicker from 'react-datepicker'


const Top3Card = ({obj1, obj2, obj3, prop, title, negative, description}) => {

    let max = obj1 ? obj1[prop] : 0;
    if(obj3 && obj3[prop] > max) max = obj3[prop]

    return (
        <Card className='custom-shadow-1 border'>
            <CardHeader className='py-4'>
                <h2 className=" mb-0">{title} </h2>
            </CardHeader>
    
            <CardBody>
                <div className="progress-wrapper pt-0">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 ${negative ? 'text-danger' : 'text-success'}`}>
                                {obj1 ? obj1.name : '-'}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{obj1 ? obj1[prop] : 0}</span>
                        </div>
                    </div>
                    <Progress max={max} value={obj1 ? obj1[prop] : 0} color={negative ? 'danger' : 'success'} />
                </div>
                <div className="progress-wrapper pt-0">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 ${negative ? 'text-warning' : 'text-info'}`}>
                                {obj2 ? obj2.name : '-'}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{obj2 ? obj2[prop] : 0}</span>
                        </div>
                    </div>
                    <Progress max={max} value={obj2 ? obj2[prop] : 0} color={negative ? 'warning' : 'info'} />
                </div>
                <div className="progress-wrapper pt-0">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 ${negative ? 'text-yellow' : 'text-purple'}`}>
                                {obj3 ? obj3.name : '-'}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{obj3 ? obj3[prop] : 0}</span>
                        </div>
                    </div>
                    <Progress max={max} value={obj3 ? obj3[prop] : 0} color={negative ? 'yellow' : 'purple'} />
                </div>
        
            </CardBody>

            {description ? (
                <CardFooter>
                    <p className='text-sm mb-0'><i className="fas fa-info-circle mr-2 text-info" /> {description}</p>
                </CardFooter>
            ) : ''}
        </Card>
    )
    
}
const MarketingOverview = ({selected_division, DOMAINS}) => {

    const lead_source = getUrlParameter('lead_source')

    const [day, setDay] = useState(moment().startOf('day').toDate())
    const [data, setData] = useState(null)

    const [err, setErr] = useState(null)

    const getCallSummary = useCallback((lead) => {
        const found = lead.calls.find(c => c.ai_summary)
        return found && found.ai_summary ? found.ai_summary.length > 200 ? found.ai_summary.slice(0,200) + '...' : found.ai_summary : '-'
    }, [])

    const getPercent = useCallback((num1, num2) => {
        if(!num1 && !num2) return  0
        return parseFloat(((num1 / num2) * 100).toFixed(2))
    }, [])

    const getLeadMetricCard = useCallback((title, prop) => {

        const percent = getPercent(data.breakdown[prop], data.breakdown.leads)

        let color = 'danger'

        if(percent >= 90) {
            color = 'success'
        } else if(percent >= 80) {
            color = 'info'
        } else if(percent >= 65) {
            color = 'warning'
        } else if(percent >= 50) {
            color = 'danger'
        } else if(percent === 0 ) {
            color = ''
        }

        if(title.includes('INBOUND')) color = ''

        return (
            <Col>
                <Card className={`text-center ${color ? 'text-white' : ''} bg-gradient-${color}`}>
                    <CardHeader className={`bg-gradient-${color}`}>
                        <CardTitle className='mb-0'>{title}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className={`display-4 mb-0 ${color ? 'text-white' : ''}`}>{data.breakdown[prop]}</h2>
                    </CardBody>
                    <CardFooter className={`bg-gradient-${color}`}>
                        <p className='mb-0'>{percent}%</p>
                    </CardFooter>
                </Card>
            </Col>
        )
    }, [data, getPercent])

    const fetchData = useCallback(async () => {
        setData(null)

        const _data = await api.marketing.daily({
            division: selected_division._id,
            start: parseInt(moment(day).startOf('day').format('X')),
            end: parseInt(moment(day).endOf('day').format('X')),
            lead_source: lead_source ? lead_source : undefined
        })

        if(!_data.success) return setErr(_data.message ? _data.message : 'Server Error')
        setData(_data.data)

    }, [day, lead_source, selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError error={err} />
    if(!data) return <div className='py-6'><Circle /></div>

    return (

        <>

        <div className=' m4-3'>
            <Row>
                <Col md={9} className='align-self-center'>
                    <h2 className='mb-0 display-3'>Daily Overview</h2>
                </Col>
                <Col md={3} className='align-self-center text-right'>
                    {/* <p className='text-sm mb-0'>Select Day</p> */}
                    <DatePicker 
                        selected={day} 
                        onChange={(date) => setDay(date)} 
                    />
                </Col>
            </Row>
        </div>

        <hr className='mt-3'/>

        <Row>
            
            <Col md={3}>
                <Card className='custom-shadow-1 border'>
                    <CardHeader className='py-4'>
                        <h2 className=" mb-0">Result Breakdown</h2>
                    </CardHeader>

                    <CardHeader className='text-cente'>
                        <CardTitle className=" mb-0">Positive Numbers</CardTitle>
                    </CardHeader>
            
                    <CardHeader>
                        <div className="progress-wrapper pt-0">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-info`}>
                                        Leads
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{data.breakdown.leads}</span>
                                </div>
                            </div>
                            <Progress max={data.breakdown.leads} value={data.breakdown.leads} color={'info'} />
                        </div>
                        <div className="progress-wrapper pt-0">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-success`}>
                                        Opportunities
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{data.breakdown.opportunities}</span>
                                </div>
                            </div>
                            <Progress max={data.breakdown.leads} value={data.breakdown.opportunities} color={'success'} />
                        </div>
                        <div className="progress-wrapper pt-0">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-success`}>
                                        Hires
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{data.breakdown.hire}</span>
                                </div>
                            </div>
                            <Progress max={data.breakdown.leads} value={data.breakdown.hire} color={'success'} />
                        </div>
                        
                        <div className="progress-wrapper pt-0">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-success`}>
                                        Won
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{data.breakdown.won}</span>
                                </div>
                            </div>
                            <Progress max={data.breakdown.leads} value={data.breakdown.won} color={'success'} />
                        </div>
                    </CardHeader>

                    <CardHeader className='text-cente'>
                        <CardTitle className=" mb-0">Neutral Numbers</CardTitle>
                    </CardHeader>

                    <CardHeader>
                    

                        <div className="progress-wrapper pt-0">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-purple`}>
                                        Referred Out
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{data.breakdown.refer_out}</span>
                                </div>
                            </div>
                            <Progress max={data.breakdown.leads} value={data.breakdown.refer_out} color={'purple'} />
                        </div>
                    </CardHeader>

                    <CardHeader className='text-cente'>
                        <CardTitle className=" mb-0">Negative Numbers</CardTitle>
                    </CardHeader>

                    <CardHeader >
                        
                        <div className="progress-wrapper pt-0">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-danger`}>
                                        No Hire
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{data.breakdown.no_hire}</span>
                                </div>
                            </div>
                            <Progress max={data.breakdown.leads} value={data.breakdown.no_hire} color={'danger'} />
                        </div>

                        <div className="progress-wrapper pt-0">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-danger`}>
                                        DNQ
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{data.breakdown.dnq}</span>
                                </div>
                            </div>
                            <Progress max={data.breakdown.leads} value={data.breakdown.dnq} color={'danger'} />
                        </div>
                        
                    </CardHeader>

                </Card>
            </Col>

            <Col md={3}>
                <Top3Card 
                    title="Highest Contributing Users"
                    description="Users that touched the most leads for the given day."
                    prop="leads"
                    obj1={data.contributing_users[0]}
                    obj2={data.contributing_users[1]}
                    obj3={data.contributing_users[2]}
                />
                {/* <Top3Card 
                    title="Lowest Contributing Users"
                    description="Users that touched the most leads for the given day."
                    prop="leads"
                    obj1={data.contributing_users[data.contributing_users.length -3] ? data.contributing_users[data.contributing_users.length -3] : ''}
                    obj2={data.contributing_users[data.contributing_users.length -2] ? data.contributing_users[data.contributing_users.length -2] : ''}
                    obj3={data.contributing_users[data.contributing_users.length -1] ? data.contributing_users[data.contributing_users.length -1] : ''}
                    negative={true}
                /> */}
                <Top3Card 
                    title="Highest Contributing Sources"
                    description="Sources that contributed the most leads."
                    prop="leads"
                    obj1={data.sources.contributing_sources[0]}
                    obj2={data.sources.contributing_sources[1]}
                    obj3={data.sources.contributing_sources[2]}
                />
            </Col>

            <Col md={6}>
                <PieArea 
                    small={true}
                    type="pie"
                    height={391}
                    title="Contributing Source Breakdown"
                    labels={data.sources.contributing_sources.map(s => s.name)}
                    dataset={data.sources.contributing_sources.map(s => s.leads)}
                />
                {/* <PieArea 
                    small={true}
                    type="pie"
                    title="Users That Worked Leads"
                    labels={data.contributing_users.map(s => s.name)}
                    dataset={data.contributing_users.map(s => s.leads)}
                /> */}
            </Col>

        </Row>

        <Row>
            <Col md={8}>
                    <h2><i className="fas fa-headset mr-2 text-info" /> Phone Calls</h2>
                <Row>
                    {getLeadMetricCard('Has Phone Call', 'has_call')}
                    {getLeadMetricCard('Has INBOUND Call', 'has_call_inbound')}
                    {getLeadMetricCard('Has OUTBOUND Call', 'has_call_outbound')}
                </Row>

                <h2><i className="fas fa-mobile mr-2 text-info" /> Text Messages</h2>
                <Row>
                    {getLeadMetricCard('Has Text Message', 'has_text')}
                    {getLeadMetricCard('Has INBOUND Text Message', 'has_text_inbound')}
                    {getLeadMetricCard('Has OUTBOUND Text Message', 'has_text_outbound')}
                </Row>

                <h2><i className="fas fa-envelope mr-2 text-info" /> Emails</h2>
                <Row>
                    {getLeadMetricCard('Has Email', 'has_email')}
                    {getLeadMetricCard('Has INBOUND Email', 'has_email_inbound')}
                    {getLeadMetricCard('Has OUTBOUND Email', 'has_email_outbound')}
                </Row>

                <h2><i className="fas fa-info-circle mr-2 text-info" /> Miscellaneous</h2>
                <Row>
                    {getLeadMetricCard('Note Found On Matter', 'has_note')}
                    {getLeadMetricCard('Scheduled Event Found', 'has_email')}
                </Row>
            </Col>

            <Col md={4}>
                <h2><i className="fas fa-users mr-2 text-info" /> Contributing Users</h2>

                <Card >

                    <CardHeader>
                        <CardTitle className='card-title mb-0'>Uses With Actions On Leads</CardTitle>
                    </CardHeader>

                    <div className='table-responsive' style={{height: 672, overflow: 'auto'}}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th className='text-right'>Leads Worked</th>
                                </tr>
                            </thead>

                            <tbody >
                                {data.contributing_users.length ? data.contributing_users.map(u => (
                                    <tr>
                                        <td>{u.name}</td>
                                        <td className='text-right'>{u.leads}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No Users Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    
                </Card>
            </Col>
        </Row>

        {/* data.contributing_users */}

        <Card >
            <CardHeader>
                <CardTitle className='card-title mb-0'>Lead Records</CardTitle>
            </CardHeader>

            <div className='table-responsive' style={{maxHeight: 400, overflow: 'auto'}}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Lead</th>
                            <th style={{width: 300}}>Call Summary</th>
                            <th>Handled By</th>
                            <th>#<i className="fas fa-headset" /></th>
                            <th><i className="fas fa-gavel" /></th>

                            <th><i className="fas fa-headset" /></th>
                            <th><i className="fas fa-headset" /> <i className="fas fa-arrow-down" /></th>
                            <th><i className="fas fa-headset" /> <i className="fas fa-arrow-up" /></th>
                            
                            <th><i className="fas fa-mobile" /></th>
                            <th><i className="fas fa-mobile" /> <i className="fas fa-arrow-down" /></th>
                            <th><i className="fas fa-mobile" /> <i className="fas fa-arrow-up" /></th>

                            <th><i className="fas fa-envelope" /></th>
                            <th><i className="fas fa-envelope" /> <i className="fas fa-arrow-down" /></th>
                            <th><i className="fas fa-envelope" /> <i className="fas fa-arrow-up" /></th>
                            
                            <th><i className="fas fa-calendar" /> </th>
                            <th><i className="fas fa-pen" /> </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.leads.length ? data.leads.map(l => (
                            <tr key={l._id}>
                                <td>
                                    <A href={`${DOMAINS.APP}/clients/${l.contact}`}>
                                        {moment.unix(l.created_at).format('hh:mm A')}
                                    </A>
                                </td>
                                <td style={{whiteSpace: 'nowrap'}}>
                                    <div> <A href={`${DOMAINS.APP}/clients/${l.contact}`}>{l.identifier}</A></div>
                                    {l.phone ? <div>{formatText(l.phone).phone()}</div> : ''}
                                    {l.email ? <div>{l.email}</div> : ''}
                                </td>
                                <td>{getCallSummary(l)}</td>
                                <td>
                                    {l.associated_user_names.length ? l.associated_user_names.map((u, i) => (
                                        <span key={i}>{i !== 0 ? ', ' : ''}{u}</span>
                                    )) : ''}
                                </td>
                                <td>{l.calls.length}</td>
                                <td>{l.matters.length ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_call ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_call_inbound ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_call_outbound ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                
                                <td>{l.has_text ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_text_inbound ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_text_outbound ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>


                                <td>{l.has_email ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_email_inbound ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_email_outbound ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                
                                <td>{l.has_event ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                                <td>{l.has_note ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Leads Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>

        <Card>
            <CardHeader>
                <CardTitle className='mb-0'>Created Opportunities</CardTitle>
            </CardHeader>

            <div className='table-responsive' style={{maxHeight: 400, overflow: 'auto'}}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Opportunity</th>
                            <th>Disposition</th>
                            <th>Time Till Created</th>
                            <th>Category</th>
                            <th>Step</th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.opportunities.length ? data.opportunities.map((d) => (
                            <tr key={d._id}>
                                <td>
                                    <A href={`${DOMAINS.APP}/matters/${d._id}`}>
                                        {d.name}
                                    </A>
                                </td>
                                <td className='text-uppercase'>{d.workflow_disposition_type ? d.workflow_disposition_type.replace('_', ' ') : ''}</td>
                                <td>{formatText(d.time_till_opportunity).secondsToTime(null, true)}</td>
                                <td>{d.workflow_step_category_name}</td>
                                <td>{d.workflow_step_name}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Opportunities Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>
        


    </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(MarketingOverview);