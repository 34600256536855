// 'VIEW.CAN_VIEW_APP_DASHBOARD' : 'VIEW.CAN_VIEW_APP_DASHBOARD',
// 'VIEW.CAN_VIEW_AFFILIATE_DASHBOARD' : 'VIEW.CAN_VIEW_AFFILIATE_DASHBOARD',
// 'VIEW.CAN_VIEW_ADMIN_DASHBOARD' : 'VIEW.CAN_VIEW_ADMIN_DASHBOARD',

import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesStandard = ({user, setUser, onPrivilegeChange, PRIVILEGES, DOMAINS}) => {

    const [standardPrivileges] = useState([
        PRIVILEGES['VIEW.CAN_VIEW_APP_DASHBOARD'],
        PRIVILEGES['VIEW.APP_DASHBOARD.BILLING'],
        PRIVILEGES['VIEW.APP_DASHBOARD.PIPELINE'],
        PRIVILEGES['VIEW.APP_DASHBOARD.MATTERS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.CONTACTS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.LEADS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.TASKS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.OUTSTANDING_ITEMS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.EVENTS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.DOCUMENTS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.TEXTS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.EMAILS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.SUPPORT_TICKETS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.VOICEMAIL'],
        PRIVILEGES['VIEW.APP_DASHBOARD.PHONE_CALLS'],
        PRIVILEGES['VIEW.APP_DASHBOARD.CUSTOM_VIEWS'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>APP DASHBOARD</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Privilege 
                privilege={PRIVILEGES['VIEW.CAN_VIEW_APP_DASHBOARD']}
                user={user}
                title={`Can View Main Application Dashboard - ${DOMAINS.APP}`}
                onPrivilegeChange={onPrivilegeChange}
            />

            <div className="pl-7 pt-3">
                <div className="pl-3">
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.PIPELINE']}
                        user={user}
                        title={`Can View The Pipeline Page`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.MATTERS']}
                        user={user}
                        title={`Can View Matter Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.CONTACTS']}
                        user={user}
                        title={`Can View Contact Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.LEADS']}
                        user={user}
                        title={`Can View Lead Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.TASKS']}
                        user={user}
                        title={`Can View Task Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.OUTSTANDING_ITEMS']}
                        user={user}
                        title={`Can View Outstanding Items Page`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.EVENTS']}
                        user={user}
                        title={`Can View Event/Calendar Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.DOCUMENTS']}
                        user={user}
                        title={`Can View Document Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.TEXTS']}
                        user={user}
                        title={`Can View Text Message Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.EMAILS']}
                        user={user}
                        title={`Can View Email Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.SUPPORT_TICKETS']}
                        user={user}
                        title={`Can View Support Ticket Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.VOICEMAIL']}
                        user={user}
                        title={`Can View Voicemail Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.PHONE_CALLS']}
                        user={user}
                        title={`Can View Phone Record Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.CUSTOM_VIEWS']}
                        user={user}
                        title={`Can See Custom View Pages`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.APP_DASHBOARD.BILLING']}
                        user={user}
                        title={`Can View Billing`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                 </div>
            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesStandard);