import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Container } from "reactstrap";

import ConfirmationModal from 'components/functional/modals/Confirmation'
import Circle from 'components/markup/loading/Circle'
import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import { toggleAlertBS, toggleStandardLoader } from "store/functions/system/system";
import api from 'api'

import Categories from './Categories'
import Dispositions from './Dispositions'
import Roles from './Roles'
import Settings from './Settings'
import Steps from './Steps'
import Navigation from './Navigation'
import Locations from './Locations'
import Contacts from './Contacts'
import Tasks from './Tasks'
import Links from './Links'
import Linearity from './Linearity'
import ClosingReasons from './ClosingReasons'
import Items from './Items'
// import Folders from './Folders'

import { setUrlParameter, getUrlParameter } from "utils/urls";

const WorkflowsEdit = ({match}) => {

    const [redirect, setRedirect] = useState(false)
    const [showUnsavedChanged, setShowUnsavedChanged] = useState(false)
    const [tab, setTab] = useState('Navigation')
    const [tabForced, setTabForced] = useState(0)
    const [unsavedChanges, setUnsavedChanges] = useState(0)
    const [workflow, setWorkflow] = useState(null)

    const beforeUnload = useCallback((e) => {
        e.preventDefault();
        return e.returnValue = "Are you sure you want to exit?";
    }, [])

    const onSetUnsavedChanges = useCallback((_unsavedChanges) => {
        if(unsavedChanges !== _unsavedChanges) setUnsavedChanges(_unsavedChanges)

        if(_unsavedChanges) {
            window.addEventListener('beforeunload', beforeUnload)
        } else {
            window.removeEventListener('beforeunload', beforeUnload)
        }
    }, [unsavedChanges, beforeUnload])

    const onSetTab = useCallback((_tab) => {
        setUrlParameter('tab', _tab)
        if(!unsavedChanges) return setTab(_tab)

        setTabForced(_tab)
        setShowUnsavedChanged(true)
    }, [unsavedChanges])

    const onSetWorkflow = useCallback(async (_workflow) => {
        setWorkflow(_workflow);
        onSetUnsavedChanges(false)
        toggleStandardLoader(true)
        let saved;
        if(_workflow._id) {
            saved = await api.workflows.update(_workflow._id, _workflow)
        } else {
            saved = await api.workflows.create(_workflow)
        }
        toggleStandardLoader(false)

        if(saved.success) {
            toggleAlertBS(false, `Workflow Saved Successfully`)
            setWorkflow(saved.data);
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }

        // save to db here
    }, [onSetUnsavedChanges])

    const fetchWorkflow = useCallback(async () => {
        const workflow_id = match.params.template_id;
        const found = await api.workflows.findById(workflow_id, true);
        if(!found.data) return setRedirect('/system/workflows');
        setWorkflow(found.data)
    }, [match.params.template_id])

    useEffect(() => {
        fetchWorkflow()

        const tab = getUrlParameter('tab');
        if(tab) setTab(tab)
    }, [fetchWorkflow])

    if(redirect) return <Redirect to={redirect} />
    if(!workflow) return <div className="py-5"><Circle /></div>

    return (

        <>

        <Helmet>
            <title>{`Workflows`}</title>
            <meta name="description" content="Workflows" />
        </Helmet>

        <ConfirmationModal 
            showModal={showUnsavedChanged}
            toggleModal={() => setShowUnsavedChanged(false)}
            title="Unsaved Changes"
            body={(
                <span>
                    You currently have unsaved changes, are you sure you with to proceed?
                </span>
            )}
            onConfirmation={() => {
                setTab(tabForced)
                onSetUnsavedChanges(false)
            }}
        />

        <div className="archk-workflows-header">
        <HeaderNavigation 
            title={<span><i className="fas fa-edit mr-0 text-info " /> Editing Workflow: {workflow.name ? workflow.name.length > 30 ? workflow.name.slice(0, 30) + '...' : workflow.name : 'Creating New'} </span>}
            // description={`${workflow.steps.length} Steps  ${workflow.roles.length} Roles  ${workflow.categories.length} Categories   ${workflow.dispositions.length} Dispositions  ${workflow.fields.length} Fields`}
            actionComponent={(
                tab === 'Navigation' ? (
                    <Link to="/system/workflows" className="btn btn-warning">
                        <i className="fas fa-arrow-left mr-2" /> All Workflows
                    </Link>
                ) :  (
                    // <button style={{marginRight: tab === 'Steps' ? 180 : 0}} onClick={() => onSetTab('Navigation')} className="btn btn-outline-info">
                    <button style={{marginRight: tab === 'Steps' ? 145 : 0}} onClick={() => onSetTab('Navigation')} className="btn btn-outline-info">
                        <i className="fas fa-arrow-left mr-2" /> Back
                    </button>
                )
            )}
        />
        </div>

        <Container fluid={tab === 'Steps' ? true : true}>       
            {tab === 'Steps' ? (
                <Steps 
                    onSetUnsavedChanges={onSetUnsavedChanges}
                    onSetWorkflow={onSetWorkflow}
                    workflow={workflow}
                />
            ) 
            // : tab === 'Folders' ? (
            //     <Folders 
            //         onSetUnsavedChanges={onSetUnsavedChanges}
            //         onSetWorkflow={onSetWorkflow}
            //         workflow={workflow}
            //     />
            // )  
            : tab === 'Settings' ? (
                <Settings 
                    onSetUnsavedChanges={onSetUnsavedChanges}
                    onSetWorkflow={onSetWorkflow}
                    workflow={workflow}
                />
            ) : tab === 'Roles' ? (
                <Roles  workflow={workflow} />
            ) : tab === 'Dispositions' ? (
                <Dispositions workflow={workflow} />
            ) : tab === 'Categories' ? (
                <Categories workflow={workflow} />
            ) : tab === 'Locations' ? (
                <Locations workflow={workflow} />
            ) : tab === 'Links' ? (
                <Links workflow={workflow} />
            )  : tab === 'Contacts' ? (
                <Contacts workflow={workflow} />
            ) : tab === 'Tasks' ? (
                <Tasks workflow={workflow} match={match} />
            ) : tab === 'ClosingReasons' ? (
                <ClosingReasons workflow={workflow} match={match} onSetUnsavedChanges={onSetUnsavedChanges} />
            ) : tab === 'Linearity' ? (
                <Linearity workflow={workflow} match={match} onSetUnsavedChanges={onSetUnsavedChanges} />
            ) : tab === 'Items' ? (
                <Items workflow={workflow} match={match} onSetUnsavedChanges={onSetUnsavedChanges} />
            )  : (
                <Navigation onSetTab={onSetTab} />
            )}
        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company,
	};
};

export default connect(mapStateToProps, '')(WorkflowsEdit);
