import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';

const WorkflowSidebarTags = ({ stepToEdit, onAddArrayField, onRemoveArrayField, onSetStepToEdit, selected_division }) => (

    <div>
        <h2 className='text-warning'>STEP ASSIGNMENTS</h2>

        <SearchCollections
            collection="tags" 
            title="Step Tags"
            value={null}
            onChange={(obj) => onAddArrayField('tags', obj.value)}
            filter={{
                deleted: false,
                division: selected_division._id
            }}
        /> 

        {stepToEdit.tags.length ? stepToEdit.tags.map((tag, i) => (
            <Badge key={tag} color="success" className="ml-0 mb-2 text-left mr-3">
                <ObjectFinder collection="tags" _id={tag} />
                <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('tags', tag)}>
                    <i className="fas fa-times " />
                </span>
            </Badge>
        )) : null}

        <SearchCollections
            collection="automations" 
            title="Step Automations"
            value={null}
            onChange={(obj) => onAddArrayField('automations', obj.value)}
            filter={{
                division: selected_division._id
            }}
        /> 

        {stepToEdit.automations.length ? stepToEdit.automations.map((automation, i) => (
            <Badge key={automation} color="success" className="ml-0 mb-2 text-left mr-3">
                <ObjectFinder collection="automations" _id={automation} />
                <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('automations', automation)}>
                    <i className="fas fa-times " />
                </span>
            </Badge>
        )) : null}

        <hr/>

        <ReactSelect 
            formGroup={true}
            title="End All Automations"
            description="Note that automations selected above will be assigned after removing all currently assigned ones"
            value={stepToEdit.end_automations}
            onChange={(obj) => onSetStepToEdit('end_automations', obj.value)}
            options={[
                { value: false, label: 'No' },
                { value: true, label: 'Yes***' },
            ]}
        />
      
        <ReactSelect 
            formGroup={true}
            title="Show On Historical Reports"
            description="If set to yes, this step will show on the charts under the management dashboard historical reports"
            value={stepToEdit.track_historically}
            onChange={(obj) => onSetStepToEdit('track_historically', obj.value)}
            options={[
                { value: false, label: 'No' },
                { value: true, label: 'Yes' },
            ]}
        />

        {/* <hr/>

        <SearchCollections
            collection="event_templates" 
            title={(
                <span>
                    Required Events
                </span>
            )}
            value={stepToEdit.requires_events}
            hideSelected={true} 
            onChange={(obj) => onAddArrayField('requires_events', obj.value)}
            filter={{
                division: selected_division._id
            }}
        /> 
        {stepToEdit.requires_events.map((template, i) => (
            <Badge key={template} color="success" className="ml-0 mb-3 text-left mr-2">
                <ObjectFinder collection="event_templates" _id={template} />
                <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('requires_events', template)}>
                    <i className="fas fa-times " />
                </span>
            </Badge>
        ))} */}

    </div>
)


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarTags);