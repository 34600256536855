import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Input } from "reactstrap";

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import { toast } from 'react-toastify';

const Category = ({category, onDeleted}) => {

    const [name, setName] = useState(category.name)

    const onDelete = useCallback(async () => {

        const result = await api.outstanding_item_categories.delete(category._id)
        if(result.success) onDeleted(category)
        
    }, [category, onDeleted])

    const onUpdate = useCallback(async () => {
        if(!name) return toast.info(`You must add a name before creating a category`)

        const result = await api.outstanding_item_categories.update(category._id, { name })
        if(!result.success) return toast.error(`Could not update the category at this time.`)

    }, [category._id, name])


    return (
        <CardFooter className="position-relative">
               <Input 
                    type="text"
                    value={name}
                    onBlur={onUpdate}
                    onChange={e => setName(e.target.value)}
                />
                <button 
                    style={{position: 'absolute', right: 12, top: 8, paddingTop: 5, paddingBottom: 5, borderRadius: 0}} 
                    className="btn btn-danger "
                    onClick={onDelete}
                >
                    <i className="fas fa-trash" />
                </button>

            
        </CardFooter>
    )
}

const ItemCategories = ({setTab, workflow}) => {

    const [categories, setCategories] = useState(null)
    const [name, setName] = useState('')

    const onDeleted = useCallback((category) => {
        let state = JSON.parse(JSON.stringify(categories))
        state = state.filter(s => s._id !== category._id)
        setCategories(state)
    }, [categories])

    const fetchData = useCallback(async () => {
        const data = await api.outstanding_item_categories.find(workflow._id)
        console.log(data)
        setCategories(data.data)
    }, [workflow._id])

    const onCreate = useCallback(async () => {
        if(!name) return toast.info(`You must add a name before creating a category`)

        const result = await api.outstanding_item_categories.create({ name, workflow: workflow._id })
        if(!result.success) return toast.error(`Could not create the category at this time.`)

        const state = JSON.parse(JSON.stringify(categories))
        state.unshift(result.data)
        setCategories(state)
        
        setName('')

        document.getElementById('archk-add-outstanding-item-category').focus()
    }, [categories, name, workflow._id])


	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') onCreate();
	}, [onCreate])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!categories) return <Circle />

    return (

        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Add Item Category</CardTitle>
            </CardHeader>

            <CardBody className="position-relative">
                <Input 
                    id="archk-add-outstanding-item-category"
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    onKeyDown={_handleKeyDown}
                />
                <button 
                    style={{position: 'absolute', right: 12, top: 12, paddingTop: 6, paddingBottom: 6, borderRadius: 0}} 
                    className="btn btn-success "
                    onClick={onCreate}
                >
                    <i className="fas fa-plus" />
                </button>

            
            </CardBody>

            {categories.length ? categories.map(c => (
                <Category key={c._id} category={c} onDeleted={onDeleted}  />
            )) : (
                <CardFooter>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info" /> No Categories Found.</p>
                </CardFooter>
            )}

        </Card>
    )

}


const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(ItemCategories);