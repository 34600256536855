import { useCallback, useState } from 'react';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from 'reactstrap';

import api from 'api';

import { toast } from 'react-toastify';

import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from "components/system/Search/Collections";

const baseObj = { name: '', workflow_step_categories: [] }

const WorkflowEditRoles = ({workflow, onSetWorkflow, onSetUnsavedChanges}) => {

    const [linearity, setLinearity] = useState(workflow.linearity && workflow.linearity.length ? workflow.linearity : [baseObj])

    const onNameChange = useCallback((index, value) => {
        const state = JSON.parse(JSON.stringify(linearity));
        const obj = state[index]
        obj.name = value
        onSetUnsavedChanges(true)
        setLinearity(state)
    }, [linearity, onSetUnsavedChanges])
    
    const onAddCategory = useCallback((index, value) => {
        const state = JSON.parse(JSON.stringify(linearity));
        const obj = state[index]

        if(!obj.workflow_step_categories.includes(value)) obj.workflow_step_categories.push(value)

        onSetUnsavedChanges(true)
        setLinearity(state)
    }, [linearity, onSetUnsavedChanges])
   
    const onRemoveCategory = useCallback((index, value) => {
        const state = JSON.parse(JSON.stringify(linearity));
        const obj = state[index]

        obj.workflow_step_categories = obj.workflow_step_categories.filter(w => w !== value)

        onSetUnsavedChanges(true)
        setLinearity(state)
    }, [linearity, onSetUnsavedChanges])

    const onAddGrouping = useCallback(() => {
        const state = JSON.parse(JSON.stringify(linearity));
        state.push(baseObj)
        onSetUnsavedChanges(true)
        setLinearity(state)
    }, [linearity, onSetUnsavedChanges])

    const onRemoveGrouping = useCallback((index) => {
        let state = JSON.parse(JSON.stringify(linearity));
        state.splice(index, 1)
        onSetUnsavedChanges(true)
        setLinearity(state)
        console.log(state)
    }, [linearity, onSetUnsavedChanges])

    const onSave = useCallback(async () => {
        let err;

        linearity.forEach(c => {
            if(!c.name) err = `A name must be given for each grouping category.`;
            if(!c.workflow_step_categories.length) err = `Each grouping must have atleast 1 category associated with it.`;
        })

        if(err) return toast.info(err)

        const saved = await api.workflows.update(workflow._id, { linearity })
        onSetUnsavedChanges(false);

        if(saved.success) {
            toast.success('Workflow Updated Successfully')
        } else {
            toast.error(`Something's not right, please try again.`)
        }

    }, [linearity, onSetUnsavedChanges, workflow._id])

    return (
        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Workflow Settings</CardTitle>
                </CardHeader>

                <CardBody>
                    <p className='text-sm'>The linearity of a workflow is designed to combine workflow categories in a point A <i className="fas fa-arrow-right mx-2" /> Point B fashion. This of the first grouping you add as where you business process start and the last grouping as where your business processes finishes when successful.</p>
                    <p className='text-sm'><i className="fas fa-info-circle text-info mr-2" /> As an example, if you workflow starts with the sales process, goes into a fulfillment stage and ends with a deliverable your categorize may resemble "Potential Client", "Processing", "Product Delivered".</p>
                    <p className='text-sm mb-0'><i className="fas fa-info-circle text-info mr-2" /> Note that the order you add your grouping below determines how they will show up within IntuiDesk.</p>

                </CardBody>

                <CardFooter className='text-right'>
                    <button onClick={onSave} className="btn btn-success">
                        <i className="fas fa-save mr-2 " /> Save Settings
                    </button>
                </CardFooter>
            </Card>

            {linearity.map((l, i) => (
                <Card key={i}>
                    <CardHeader>
                        <FormGroup>
                            <label className='form-control-label'>Grouping #{i + 1  } Stage Name</label>
                            <Input 
                                type="text"
                                value={l.name}
                                onChange={e => onNameChange(i, e.target.value)}
                            />
                        </FormGroup>
                    </CardHeader>

                    <CardBody>


                        <SearchCollections
                            title="Select Step Categories"
                            collection="workflow_step_categories"
                            placeholder="Search.."
                            hideSelected={true}
                            onChange={(obj) => onAddCategory(i, obj.value)}
                            filter={{
                                workflow: workflow._id
                            }}
                        /> 

                        {l.workflow_step_categories.map(c => (
                            <Badge color="success" key={c}>
                                <ObjectFinder collection="workflow_step_categories" _id={c} />{' '}
                                <i onClick={() => onRemoveCategory(i, c)} className="fas fa-times pl-3 cursor-pointer" />
                            </Badge>
                        ))}

                    </CardBody>

                    <CardFooter>
                        <button onClick={() => onRemoveGrouping(i)} className='btn btn-sm btn-outline-danger'>Remove Grouping</button>
                    </CardFooter>
                </Card>
            ))}

            <div className='text-right'>
                <button onClick={onAddGrouping} className='btn btn-outline-info'><i className="fas fa-plus mr-2" /> Add Grouping</button>
            </div>

        </div>

    )

}

export default WorkflowEditRoles;