import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import RowReason from './RowReason';

const WorkflowEditReasons = ({workflow}) => {

    const [reason, setReason] = useState('')
    const [reasons, setReasons] = useState(workflow.reasons)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-reason-bottom").click();
	}, [])

    const onAdd = useCallback(async () => {
        if(!reason) return;
        const _reasons = JSON.parse(JSON.stringify(reasons));

        const created = await api.matter_closing_reasons.create({
            workflow: workflow._id,
            division: workflow.division,
            name: reason,
        })
        if(created.success) {
            _reasons.push(created.data)
            setReasons(_reasons);
            setReason('')   
        } else {
            toast.error("Something went wrong creating this closing reason, please try again and make sure the reason is unique.")
        }
      
    }, [reason, reasons, workflow._id, workflow.division])
    
    const onDelete = useCallback(async (_reason) => {
        let _reasons = JSON.parse(JSON.stringify(reasons));

        const deleted = await api.matter_closing_reasons.delete(_reason._id)
        if(deleted.success) {
            _reasons = _reasons.filter(r => r._id !== deleted.data._id)
            setReasons(_reasons);
        } else {
            toast.error("Something went wrong deleting this closing reason, please try again.")
        }
    }, [reasons])

    const fetchData = useCallback(async () => {
        const data = await api.matter_closing_reasons.find(workflow._id);
        if(data.data) setReasons(data.data)
    }, [workflow._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Add Matter Closing Reason</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Closing reasons are selectable options by a user when moving to a step marked as "Closed". The options you create below will show in the marketing sections of the dashboard..</p>

            </CardHeader>
            <CardHeader>
            
                <label className="form-control-label">Closing Reason Text*</label>

                <Row>
                    <div className="col-auto" style={{width: 'calc(100% - 168px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}

                            />
                        </FormGroup>
                    </div>
                    <Col>
                        <button 
                            id="archk-add-reason-bottom"
                            onClick={onAdd} 
                            className="btn btn-outline-info btn-block"
                        >
                            <i className="fas fa-plus mr-2 " /> Add Reason
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {!reasons ? <Circle /> : (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Workflow Reasons</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reasons.length ? reasons.map((reason) => <RowReason reason={reason} onDelete={onDelete} />) : (
                                <tr>
                                    <td>No reasons have been created for this workflow</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}


        </Card>
    )

}

export default WorkflowEditReasons;