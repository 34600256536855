import ReactSelect from 'components/functional/inputs/ReactSelect';
import { useCallback, useEffect, useState, } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';

// times = { start: 0, end: 86400 }

const getOptions = () => {
    const hour = 3600;
    const times = [
        { value: hour * 0, label: '12:00 AM' },
        { value: hour * .25, label: '12:15 AM' },
        { value: hour * .5, label: '12:30 AM' },
        { value: hour * .75, label: '12:45 AM' },
       
        { value: hour * 1, label: '1:00 AM' },
        { value: hour * 1.25, label: '1:15 AM' },
        { value: hour * 1.5, label: '1:30 AM' },
        { value: hour * 1.75, label: '1:45 AM' },
      
        { value: hour * 2, label: '2:00 AM' },
        { value: hour * 2.25, label: '2:15 AM' },
        { value: hour * 2.5, label: '2:30 AM' },
        { value: hour * 2.75, label: '2:45 AM' },
      
        { value: hour * 3, label: '3:00 AM' },
        { value: hour * 3.25, label: '3:15 AM' },
        { value: hour * 3.5, label: '3:30 AM' },
        { value: hour * 3.75, label: '3:45 AM' },
     
        { value: hour * 4, label: '4:00 AM' },
        { value: hour * 4.25, label: '4:15 AM' },
        { value: hour * 4.5, label: '4:30 AM' },
        { value: hour * 4.75, label: '4:45 AM' },
      
        { value: hour * 5, label: '5:00 AM' },
        { value: hour * 5.25, label: '5:15 AM' },
        { value: hour * 5.5, label: '5:30 AM' },
        { value: hour * 5.75, label: '5:45 AM' },
      
        { value: hour * 6, label: '6:00 AM' },
        { value: hour * 6.25, label: '6:15 AM' },
        { value: hour * 6.5, label: '6:30 AM' },
        { value: hour * 6.75, label: '6:45 AM' },
      
        { value: hour * 7, label: '7:00 AM' },
        { value: hour * 7.25, label: '7:15 AM' },
        { value: hour * 7.5, label: '7:30 AM' },
        { value: hour * 7.75, label: '7:45 AM' },
      
        { value: hour * 8, label: '8:00 AM' },
        { value: hour * 8.25, label: '8:15 AM' },
        { value: hour * 8.5, label: '8:30 AM' },
        { value: hour * 8.75, label: '8:45 AM' },
      
        { value: hour * 9, label: '9:00 AM' },
        { value: hour * 9.25, label: '9:15 AM' },
        { value: hour * 9.5, label: '9:30 AM' },
        { value: hour * 9.75, label: '9:45 AM' },
      
        { value: hour * 10, label: '10:00 AM' },
        { value: hour * 10.25, label: '10:15 AM' },
        { value: hour * 10.5, label: '10:30 AM' },
        { value: hour * 10.75, label: '10:45 AM' },
      
        { value: hour * 11, label: '11:00 AM' },
        { value: hour * 11.25, label: '11:15 AM' },
        { value: hour * 11.5, label: '11:30 AM' },
        { value: hour * 11.75, label: '11:45 AM' },
      
        { value: hour * 12, label: '12:00 PM' },
        { value: hour * 12.25, label: '12:15 PM' },
        { value: hour * 12.5, label: '12:30 PM' },
        { value: hour * 12.75, label: '12:45 PM' },
       
        { value: hour * 13, label: '1:00 PM' },
        { value: hour * 13.25, label: '1:15 PM' },
        { value: hour * 13.5, label: '1:30 PM' },
        { value: hour * 13.75, label: '1:45 PM' },
      
        { value: hour * 14, label: '2:00 PM' },
        { value: hour * 14.25, label: '2:15 PM' },
        { value: hour * 14.5, label: '2:30 PM' },
        { value: hour * 14.75, label: '2:45 PM' },
      
        { value: hour * 15, label: '3:00 PM' },
        { value: hour * 15.25, label: '3:15 PM' },
        { value: hour * 15.5, label: '3:30 PM' },
        { value: hour * 15.75, label: '3:45 PM' },
     
        { value: hour * 16, label: '4:00 PM' },
        { value: hour * 16.25, label: '4:15 PM' },
        { value: hour * 16.5, label: '4:30 PM' },
        { value: hour * 16.75, label: '4:45 PM' },
      
        { value: hour * 17, label: '5:00 PM' },
        { value: hour * 17.25, label: '5:15 PM' },
        { value: hour * 17.5, label: '5:30 PM' },
        { value: hour * 17.75, label: '5:45 PM' },
      
        { value: hour * 18, label: '6:00 PM' },
        { value: hour * 18.25, label: '6:15 PM' },
        { value: hour * 18.5, label: '6:30 PM' },
        { value: hour * 18.75, label: '6:45 PM' },
      
        { value: hour * 19, label: '7:00 PM' },
        { value: hour * 19.25, label: '7:15 PM' },
        { value: hour * 19.5, label: '7:30 PM' },
        { value: hour * 19.75, label: '7:45 PM' },
      
        { value: hour * 20, label: '8:00 PM' },
        { value: hour * 20.25, label: '8:15 PM' },
        { value: hour * 20.5, label: '8:30 PM' },
        { value: hour * 20.75, label: '8:45 PM' },
      
        { value: hour * 21, label: '9:00 PM' },
        { value: hour * 21.25, label: '9:15 PM' },
        { value: hour * 21.5, label: '9:30 PM' },
        { value: hour * 21.75, label: '9:45 PM' },
      
        { value: hour * 22, label: '10:00 PM' },
        { value: hour * 22.25, label: '10:15 PM' },
        { value: hour * 22.5, label: '10:30 PM' },
        { value: hour * 22.75, label: '10:45 PM' },
      
        { value: hour * 23, label: '11:00 PM' },
        { value: hour * 23.25, label: '11:15 PM' },
        { value: hour * 23.5, label: '11:30 PM' },
        { value: hour * 23.75, label: '11:45 PM' },
        { value: hour * 24, label: '12:00 PM (EOD)' },

    ]
    return times
}

const Day = ({value, property, label, onSelectTime}) => {

    const getValue = useCallback((str) => {
        var sec_num = parseInt(str, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);


        let identifier = hours < 12 ? 'AM' : 'PM';
        if(hours > 12) hours = hours - 12;
        if(hours === 0) hours = 12;

    
        if (minutes < 10) {minutes = "0"+minutes;}
        return hours+':'+minutes + ' ' + identifier;
    }, [])

    return (

        <div className="border bg-secondar mb-3">

            <div className="px-3 py-2 border-bottom">
                {!value.start && !value.end ? (
                    <small className="text-warning">{label} Hours: No Times Available</small>
                ) : value.start >= value.end ? (
                    <small className="text-danger"><i className="fas fa-exclamation-triangle " /> End time must be greater than the start time.</small>
                ) : (
                    <small>{label} Hours: <b>{getValue(value.start)}</b> to <b>{getValue(value.end)}</b></small>
                )}
            </div>
            
            <div className="p-3">
                <Row>
                    <Col md={6} className="align-self-center">
                        <FormGroup>
                            <small>Start</small>
                            <ReactSelect 
                                value={value.start}
                                onChange={(e) => onSelectTime(e.value, property, 'start')}
                                options={getOptions()}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} className="align-self-center">
                        <FormGroup>
                            <small>End</small>
                            <ReactSelect 
                                value={value.end}
                                onChange={(e) => onSelectTime(e.value, property, 'end')}
                                options={getOptions()}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

const StartAndEndOfDay = ({times, setTimes}) => {

    const [state, setState] = useState({
        mon: { start: 32400, end: 61200 },
        tue: { start: 32400, end: 61200 },
        wed: { start: 32400, end: 61200 },
        thu: { start: 32400, end: 61200 },
        fri: { start: 32400, end: 61200 },
        sat: { start: 0, end: 0 },
        sun: { start: 0, end: 0 },
    })

    const days = [
        { property: 'mon', label: 'Monday' },
        { property: 'tue', label: 'Tuesday' },
        { property: 'wed', label: 'Wednesday' },
        { property: 'thu', label: 'Thursday' },
        { property: 'fri', label: 'Friday' },
        { property: 'sat', label: 'Saturday' },
        { property: 'sun', label: 'Sunday' },
    ]

    const onSelectTime = useCallback((value, property, type) => {
        const _state = JSON.parse(JSON.stringify(state))
        _state[property][type] = parseInt(value);

        setState(_state)
        setTimes(_state)
    }, [state, setTimes])

    useEffect(() => {
        setState(times)
    }, [times])

    return (
        <div>
            {days.map((d, i) => (
                <Day 
                    key={i}
                    value={state[d.property]}
                    property={d.property}
                    label={d.label}
                    onSelectTime={onSelectTime}
                />
            ))}
        </div>
    )
        

}

export default StartAndEndOfDay