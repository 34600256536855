import { useCallback, useState } from 'react';
import { connect } from 'react-redux'
import { Col, Row } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';

import { toggleAlertBS } from 'store/functions/system/system';
import formatText from 'utils/formatText';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';

import { toast } from 'react-toastify'

import SearchCollections from "components/system/Search/Collections";

const SystemEventEdit = ({eventTemplate, setEventTemplate, minute, hour, day, selected_division}) => {

    const [hideSearch, setHideSearch] = useState(false)
    const [showNotificationInfo, setShowNotificationInfo] = useState(false)
    const [notification, setNotification] = useState({ type: 'email', time_before: 0, for: 'contacts' })
    
    const onNotificationInputChange = useCallback(( field, value) => {
        const newNotification = JSON.parse(JSON.stringify(notification))
        newNotification[field] = value;
        setNotification(newNotification);
    }, [notification])

    const onAddNotification = useCallback(() => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))
        if(!notification.type) return toast.info(`A type must be selected for a notification`)
        
        if(notification.type === 'email' && !notification.email_template) return toast.info(`An email template must be selected for this notification`)
        if(notification.type === 'sms' && !notification.text_template) return toast.info(`A text template must be selected for this notification`)

        const notifications = newEventTemplate.notifications ? newEventTemplate.notifications : []
        notifications.push(notification);

        // if(notifications.length > 5) return toggleAlertBS('info', 'You may assign a maximum of 5 notifications per event template')
        
        newEventTemplate.notifications = notifications;
        setEventTemplate(newEventTemplate)
        setNotification({ type: 'email', time_before: 0, for: 'contacts' })

        setHideSearch(true)

        setTimeout(() => {
            setHideSearch(false)
        }, [300])
    }, [eventTemplate, notification, setEventTemplate])
   
    const onRemoveNotification = useCallback((index) => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))

        const notifications = newEventTemplate.notifications
        notifications.splice(index, 1)

        console.log(notifications)
   
        newEventTemplate.notifications = notifications;
        setEventTemplate(newEventTemplate)
    }, [eventTemplate, setEventTemplate])

    return (
        <div>

            <Row>
                <Col lg={5}>
                    <div>

                        <h2>
                            Event Notifications 
                            <i onClick={() => setShowNotificationInfo(!showNotificationInfo)} className="fas fa-info-circle cursor-pointer ml-2 text-info" /> 
                        </h2>
                        {showNotificationInfo ? (
                            <p className="text-sm ">Event notifications are used to send emails and text messages out to either the contacts, users, or both that are assigned to an appointment. If no phone number or email address is found on a contact or user at the time the notification should go out no message will be sent.</p>
                        ) : ''}
                      
                        <ReactSelect 
                            formGroup={true}
                            title="Notification Type"
                            value={notification.type}
                            onChange={(obj) => onNotificationInputChange('type', obj.value)}
                            options={[
                                { value: 'email', label: 'Email' },
                                { value: 'sms', label: 'SMS' },
                            ]}
                        />
                        {/* <ReactSelect 
                            formGroup={true}
                            title="Notification Recipients"
                            value={notification.for}
                            onChange={(obj) => onNotificationInputChange('for', obj.value)}
                            options={[
                                { value: 'contacts', label: 'Contacts' },
                                { value: 'users', label: 'Users' },
                                { value: 'contacts and users', label: 'Contacts & Users' },
                            ]}
                        /> */}

                        <ReactSelect 
                            formGroup={true}
                            title="When should this notification go out before the event?*"
                            value={notification.time_before}
                            onChange={(obj) => onNotificationInputChange('time_before', obj.value)}
                            options={[
                                { value: 0, label: 'When the event is created' },
                                { value: minute * 5, label: '5 Minutes' },
                                { value: minute * 10, label: '10 Minutes' },
                                { value: minute * 15, label: '15 Minutes' },
                                { value: minute * 30, label: '30 Minutes' },
                                { value: minute * 45, label: '45 Minutes' },
                                { value: hour * 1, label: '1 Hour' },
                                { value: hour * 1.5, label: '1 Hour 30 minutes' },
                                { value: hour * 2, label: '2 Hours' },
                                { value: hour * 3, label: '3 Hours' },
                                { value: hour * 4, label: '4 Hours' },
                                { value: hour * 5, label: '5 Hours' },
                                { value: hour * 6, label: '6 Hours' },
                                { value: hour * 7, label: '7 Hours' },
                                { value: hour * 8, label: '8 Hours' },
                                { value: hour * 16, label: '16 Hours' },
                                { value: day * 1, label: '1 day' },
                                { value: day * 2, label: '2 Days' },
                                { value: day * 3, label: '3 Days' },
                                { value: day * 5, label: '5 Days' },
                                { value: day * 7, label: '7 Days' },
                                { value: day * 10, label: '10 Days' },
                                { value: day * 14, label: '14 Days' },
                                { value: day * 21, label: '21 Days' },
                                { value: day * 28, label: '28 Days' },
                                { value: day * 30, label: '30 Days' },
                                { value: day * 45, label: '45 Days' },
                                { value: day * 60, label: '60 Days' },
                                { value: day * 75, label: '75 Days' },
                                { value: day * 90, label: '90 Days' },
                                
                                { value: minute * -5, label: '5 Minutes After' },
                                { value: minute * -10, label: '10 Minutes After' },
                                { value: minute * -15, label: '15 Minutes After' },
                                { value: minute * -30, label: '30 Minutes After' },
                                { value: minute * -45, label: '45 Minutes After' },
                                { value: hour * -1, label: '1 Hour After' },
                                { value: hour * -1.5, label: '1 Hour 30 minutes After' },
                                { value: hour * -2, label: '2 Hours After' },
                                { value: hour * -3, label: '3 Hours After' },
                                { value: hour * -4, label: '4 Hours After' },
                                { value: hour * -5, label: '5 Hours After' },
                                { value: hour * -6, label: '6 Hours After' },
                                { value: hour * -7, label: '7 Hours After' },
                                { value: hour * -8, label: '8 Hours After' },
                                { value: hour * -16, label: '16 Hours After' },
                                { value: day * -1, label: '1 Day After' },
                                { value: day * -2, label: '2 Days After' },
                                { value: day * -3, label: '3 Days After' },
                                { value: day * -5, label: '5 Days After' },
                                { value: day * -7, label: '7 Days After' },
                                { value: day * -10, label: '10 Days After' },
                                { value: day * -14, label: '14 Days After' },
                                { value: day * -21, label: '21 Days After' },
                                { value: day * -28, label: '28 Days After' },
                                { value: day * -30, label: '30 Days After' },
                                { value: day * -45, label: '45 Days After' },
                                { value: day * -60, label: '60 Days After' },
                                { value: day * -75, label: '75 Days After' },
                                { value: day * -90, label: '90 Days After' },
                            ]}
                        />   

                        {hideSearch ? '' : (
                            <div>
                                {notification.type === 'email' ? (
                                    <div>
                                        <SearchCollections
                                            collection={'email_templates'}
                                            title="Email Template"
                                            placeholder=" "
                                            value={notification.email_template}
                                            onChange={(obj) => onNotificationInputChange('email_template', obj.value)}
                                            filter={{
                                                division: selected_division._id
                                            }}
                                        /> 
                                        <SearchCollections
                                            collection={'email_senders'}
                                            title="Email Sender"
                                            placeholder=" "
                                            value={notification.email_sender}
                                            onChange={(obj) => onNotificationInputChange('email_sender', obj.value)}
                                            filter={{
                                                division: selected_division._id
                                            }}
                                        /> 
                                    </div>
                                    
                                ) : (
                                    <SearchCollections
                                        collection={'text_templates'}
                                        title="Text Template"
                                        placeholder=" "
                                        value={notification.email_template}
                                        onChange={(obj) => onNotificationInputChange('text_template', obj.value)}
                                        filter={{
                                            division: selected_division._id
                                        }}
                                    /> 
                                )}
                            </div>
                        )}


                        <div className="text-right" onClick={onAddNotification}>
                            <button className="btn btn-outline-success"><i className="fas fa-plus mr-2 " /> Add Notification</button>
                        </div>

                    </div>
                </Col>

                <Col lg={7}>
                    <div>
                        <h2>Assigned Notifications</h2>

                            <div className="table-responsive border">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{width: 30}}></th>
                                            <th>Template</th>
                                            <th className="text-right">Time Before</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eventTemplate.notifications && eventTemplate.notifications.length ? eventTemplate.notifications.map((notification, i) => (
                                            <tr key={notification._id ? notification._id : uuidv4()}>
                                                <td className="text-capitalize text-center">
                                                    {notification.type === 'email' ? (
                                                        <i className="fas fa-envelope text-success" />
                                                    ) : (
                                                        <i className="fas fa-mobile text-info" />
                                                    )}
                                                </td>
                                                <td>
                                                    {notification.type === 'email' ? (
                                                        <ObjectFinder collection="email_templates" _id={notification.email_template} />
                                                    ) : (
                                                        <ObjectFinder collection="text_templates" _id={notification.text_template} />
                                                    )}
                                                </td>
                                                <td className="text-right">
                                                    {notification.time_before === 0 ? 'Event Creation' : 
                                                        formatText(notification.time_before < 1 ? notification.time_before * -1 : notification.time_before).secondsToTime()}
                                                        {notification.time_before < 0 ? 'After' : ''}
                                                </td>
                                                <td className="text-right">
                                                    <button onClick={() => onRemoveNotification(i)} className="btn btn-danger btn-sm">Remove</button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td></td>
                                                <td><i className="fas fa-info-circle mr-2 text-info" /> No Notifications Created</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                    </div>
                </Col>
            </Row>
          
        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemEventEdit);