import { Input, FormGroup, Row, Col, Badge } from "reactstrap";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useCallback, useState, useEffect, useRef } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'
import SearchCollections from 'components/system/Search/Collections'
import Circle from 'components/markup/loading/Circle'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import ModalToggler from "components/functional/modals/Toggler";
import ModalCustomFields from 'components/system/CustomFields'

import ReactQuill from 'react-quill';

import { openDocumentCenter } from 'store/functions/document_center'

import ObjectFinder from 'components/system/Objects/Finder';

import Associations from 'components/system/Associations'

const EmailTemplatesView = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const reactQuillRef = useRef();
    const [emailTemplate, setEmailTemplate] = useState(null)
    const [err, setErr] = useState(null)
    const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setEmailTemplate({ attachments: [] });
        const data = await api.email_templates.findById(_id)
        if(!data.data) return setErr(true);
        setEmailTemplate(data.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newEmailTemplate = JSON.parse(JSON.stringify(emailTemplate))
        newEmailTemplate[field] = value;
        setEmailTemplate(newEmailTemplate);
    }, [emailTemplate])

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = JSON.parse(JSON.stringify(emailTemplate));

        if(!newState.name || !newState.subject || !newState.body) {
            return setErr([`An email template must have a name, subject, and body.`])
        }
        
        setEmailTemplate(newState)

        let saved;

        // toggle loaders and update/create the data
        toggleStandardLoader(true)
        if(newState._id) {
            newState.attachments = newState.attachments.map(a => { return a._id ? a._id : a })
            saved = await api.email_templates.update(newState._id, newState, true);
        } else {
            saved = await api.email_templates.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Email Template Saved Successfully")
            fetchData();
            setLoading(true)
            setTimeout(() => setLoading(false), 500)
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [emailTemplate, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setEmailTemplate(null)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    const onUpload = (newDocs) => {

        const _emailTemplate = JSON.parse(JSON.stringify(emailTemplate))
        let attachments = _emailTemplate.attachments ? _emailTemplate.attachments : []
        attachments = attachments.concat(newDocs)
        _emailTemplate.attachments = attachments
        setEmailTemplate(_emailTemplate)
    }
    
    const onRemoveUpload = useCallback((a) => {
        const _emailTemplate = JSON.parse(JSON.stringify(emailTemplate))
        let attachments = _emailTemplate.attachments ? _emailTemplate.attachments : []
        const id = a._id ? a._id : a;

        attachments = attachments.filter(attachment => attachment._id ? attachment._id !== id : attachment !== id)

        _emailTemplate.attachments = attachments
        setEmailTemplate(_emailTemplate)
    }, [emailTemplate])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a matter doc.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={emailTemplate && emailTemplate._id ? "Update Email Template" : "Create Email Template"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!emailTemplate ? [] : loading ? <Circle /> :  [
                (
                    <div>

                        <h2>Identifier & Sender</h2>


                        <FormGroup>
                            <label className="form-control-label">Template Name*</label>
                            <Input 
                                type="text"
                                value={emailTemplate.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <SearchCollections
                            collection="email_senders" 
                            title="Email Sender"
                            value={emailTemplate.email_sender}
                            onChange={(obj) => onInputChange('email_sender', obj.value)}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 
                        <p className="text-sm mb-0">The email sender will be used as a default if this email does not come from a staff member or is sent out from a user without a linked email account.</p>
                    </div>
                ),
                (
                    <div>

                        <h2>
                            <Row>
                                <Col md={6}>
                                    Attachments
                                </Col>
                                <Col md={6} className="text-right">
                                    <button onClick={() => openDocumentCenter({url: '/v1/company_documents', onUpload: onUpload})} className="btn btn-info">Upload Documents</button>
                                </Col>
                            </Row>
                        </h2>

                        {emailTemplate.attachments && emailTemplate.attachments.length ? emailTemplate.attachments.map(a => (
                            <Badge key={a._id ? a._id : a} color="success" className="mr-3 my-2">{a.name ? a.name : <ObjectFinder collection="company_documents" _id={a} />} <i onClick={() => onRemoveUpload(a._id ? a._id : a)} className="fas fa-trash pl-2 cursor-pointer" /></Badge>
                        )) : ''}
                        
                    </div>
                ),
                (
                    <div>

                        <h2>AI Settings</h2>
                        <p className="text-sm ">If either of these option is set to true once the email template is generated the corresponding value will be re-written by AI. This will ensure variety in the delivered communication.</p>

                        <Row className="text-center">
                            <Col md={6}>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input
                                        className="custom-control-input"
                                        id={`archk_template_email-random_body`}

                                        checked={emailTemplate.randomize_body}
                                        type="checkbox"
                                        onChange={e =>  onInputChange('randomize_body', !emailTemplate.randomize_body)}
                                    />
                                    <label className="custom-control-label" htmlFor={`archk_template_email-random_body`}>
                                        Randomize Email <b className="text-underline">BODY</b>
                                    </label>
                                </div>
                            </Col>
                            
                            <Col md={6}>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input
                                        className="custom-control-input"
                                        id={`archk_template_email-random_subject`}

                                        checked={emailTemplate.randomize_subject}
                                        type="checkbox"
                                        onChange={e =>  onInputChange('randomize_subject', !emailTemplate.randomize_subject)}
                                    />
                                    <label className="custom-control-label" htmlFor={`archk_template_email-random_subject`}>
                                        Randomize Email <b className="text-underline">SUBJECT</b>
                                    </label>
                                </div>
                            </Col>
                        </Row>

                       
                       
                        
                    </div>
                ),
               
                (
                    <div>

                        <h2>Subject & Body</h2>
                        <FormGroup>
                            <label className="form-control-label">Email Subject*</label>
                            <Input 
                                type="text"
                                value={emailTemplate.subject || ''}
                                onChange={e => onInputChange('subject', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="ql-large">
                            <label className="form-control-label">Email Body*</label>
                            <ReactQuill 
                                defaultValue={emailTemplate.body || ''}
                                ref={reactQuillRef}
                                onChange={(value) => onInputChange('body', value)} 
                                theme="snow"
                                modules={{
                                    // toolbar: [
                                    //     ["bold", "italic"],
                                    // ]
                                    toolbar: [
                                        [{ 'header': [1, 2, 3, 4, 5, false] }],
                                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                        ['link'],
                                        ['clean']
                                      ],
                                }}
                            />
                        </FormGroup>

                        <ModalToggler component={ModalCustomFields}>
                            <span className="cursor-pointer"><i className="fas fa-info-circle text-info" /> Custom Fields</span>
                        </ModalToggler>
                        
                    </div>
                ),
                ...(emailTemplate._id ? [(
                    <Associations 
                        type="email_template" 
                        _id={emailTemplate._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
               
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(EmailTemplatesView);