
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setSelectedDivision } from 'store/functions/state';
import { getSoftRefreshLink, getUrlParameter } from 'utils/urls';

import Circle from 'components/markup/loading/Circle';

import Header from './Header';
import Navbar from './Navbar';
import Results from './Results';
import Sidebar from './Sidebar';

import api from 'api';
import { toast } from 'react-toastify'

import { getRangeByValue } from './RangeSelection';

import ModalSave from './ModalSave'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const AnalyticsPagesMatters = ({selected_division, columns, query, sidebar, onSortChange, initialState, title, type, analytic_report_id}) => {

    const [reportID, setReportID] = useState(getUrlParameter('report'));
    const [reportName, setReportName] = useState(getUrlParameter());
    const [isDownloading, setIsDownloading] = useState('');
    const [redirect, setRedirect] = useState('');
    const [fields, setFields] = useState(null);
    const [showModalSave, setShowModalSave] = useState(false);

    const [data, setData] = useState(null)
    const [limit, setLimit] = useState(15)
    
    const [sort, setSort] = useState({
        type: 'created_at',
        field: ''
    });
    
    const [range, setRange] = useState(getRangeByValue());
    const [filter, setFilter] = useState(initialState)

    const onSaveReport = useCallback(() => {
        const rangeStored = getRangeByValue()

        if(rangeStored && range) {
            if((rangeStored.start !== range.start) || (rangeStored.end !== range.end)) {
                return toast.error(`When saving a report you must have a time group selected. If this is your first time saving a report please select one.`)
            }
        }

        setShowModalSave(true)
    }, [range])

    const saveReport = useCallback(async (name) => {
        const group =  cookies.get('archk-query-time-group')
        if(!group) return toast.error(`A group must be selected before saving a report.`)

        const params = {
            division: selected_division._id,
            name,
            limit,
            sort,
            filter,
            group,
            type
        }

        let result;

        if(reportID) {
            result = await api.analytic_reports.update(reportID, params)
        } else {
            result = await api.analytic_reports.create(params)
        }

        if(!result.success) return toast.error(`Could not save report, please try again`)

        setReportID(result.data._id)
        setReportName(result.data.name)
        setShowModalSave(false)

        toast.success(`Report saved successfully`)
    }, [selected_division._id, limit, sort, filter, type, reportID])

    const onSetSort = useCallback((field, value) => {
        const _sort = JSON.parse(JSON.stringify(sort))
        _sort[field] = value;
        if(field === 'type') _sort.field = ''
        setSort(_sort)
        if(onSortChange) onSortChange(_sort)
    }, [sort, onSortChange])
    

    const onSetFilter = useCallback((field, value, isCustomField) => {
        const _filter = JSON.parse(JSON.stringify(filter))
        if(isCustomField) {
            _filter.custom_fields[field] = value
        } else {
            _filter[field] = value
        }
        setFilter(_filter)
    }, [filter])
    
    const onRangeChange = useCallback(({start, end}) => {
        const _range = JSON.parse(JSON.stringify(range))
        _range.start = start
        _range.end = end
        setRange(_range)
    }, [range])

    const onAddArrayItem = useCallback((type, obj) => {
        const _filter = JSON.parse(JSON.stringify(filter))
        const array = _filter[type] ? _filter[type] : [];
        const includes = array.some(a => a.value === obj.value);
        if(!includes) {
            array.push(obj);
            _filter[type] = array;
            setFilter(_filter)
        }
    }, [filter])

    const onRemoveArrayItem = useCallback((type, obj) => {
        const _filter = JSON.parse(JSON.stringify(filter))
        let array = _filter[type] ? _filter[type] : [];
        array = array.filter(a => a.value !== obj.value)
        _filter[type] = array;
        setFilter(_filter)
    }, [filter])

    const onSetDivision = useCallback((division) => {
        setRedirect(getSoftRefreshLink())
        setSelectedDivision(division._id)
    }, [])

    const runQuery = async (params, isCSV) => new Promise (async resolve => {
        let _filter = JSON.parse(JSON.stringify(filter));

        Object.keys(filter).forEach(k => {
            if(Array.isArray(_filter[k])) _filter[k] = _filter[k].map(o => o.value)
        })
        if(isCSV) {
            setIsDownloading(true);
            toast.info(`Your download has started.`)
        }
        const data = await query({
            filter: _filter, 
            sort, 
            range, 
            limit: params.limit ? params.limit : limit, 
            skip: params.skip || 0,
            isCSV
        })
        if(isCSV) {
            setIsDownloading(false);
            toast.success(`Your download has finished.`)
        }

        if(data.success) {
            if(isCSV) {
                var hiddenElement = document.createElement('a');
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + data.data;
                hiddenElement.target = '_blank';
                hiddenElement.download = `matter_export.csv`;
                hiddenElement.click();                
            } else {
                setData(data.data)
            }
        }
        return resolve(data.data)
    })

    const fetchFields = useCallback(async () => {
        const data = await api.analyze.fields(selected_division._id)

        if(getUrlParameter('report')) {
            const result = await api.analytic_reports.findById(getUrlParameter('report'))
            if(result.data) {
                setReportName(result.data.name)
                setFilter({
                    ...initialState,
                    ...result.data.filter
                })
                setRange(getRangeByValue(result.data.group))
                setLimit(result.data.limit)
                setSort(result.data.sort)

                cookies.set('archk-query-time-group', result.data.group, { path: '/' });

                let _filter = JSON.parse(JSON.stringify(result.data.filter));

                Object.keys(filter).forEach(k => {
                    if(Array.isArray(_filter[k])) _filter[k] = _filter[k].map(o => o.value)
                })

                const data = await query({
                    filter: _filter, 
                    sort: result.data.sort, 
                    range: getRangeByValue(result.data.group), 
                    limit: result.data.limit, 
                    skip: 0,
                    isCSV: false
                })

                if(data.success) {
                    setData(data.data)
                }
            }

        } else if(data.data.workflows && data.data.workflows.length) {
            const _filter = JSON.parse(JSON.stringify(initialState));
            _filter.workflows =[{
                label: data.data.workflows[0].name,
                unformatted: data.data.workflows[0].name,
                value: data.data.workflows[0]._id
            }];

            setFilter(_filter)
        }

        setFields(data.data)

        // eslint-disable-next-line
    }, [selected_division._id])

    useEffect(() => {
        fetchFields()
    }, [fetchFields])

    if(redirect) return <Redirect to={redirect} />
    if(!fields) return <Circle className="py-6" />

    return (
        <div className="archk-query ">

            <Sidebar
                fields={fields}
                filter={filter}
                onSetFilter={onSetFilter}
                onAddArrayItem={onAddArrayItem}
                onRemoveArrayItem={onRemoveArrayItem}
                sidebar={sidebar}
            />

            <div className="archk-query-main">
                
                <Navbar 
                    onSetDivision={onSetDivision}
                    title={title}
                />
                
                <Header 
                    sort={sort}
                    onSetSort={onSetSort}
                    fields={fields}
                    onRangeChange={onRangeChange}
                    runQuery={runQuery}
                    type={type}
                />

                <Results 
                    onSaveReport={onSaveReport}
                    sort={sort}
                    data={data}
                    limit={limit}
                    setLimit={setLimit}
                    runQuery={runQuery}
                    columns={columns}
                    isDownloading={isDownloading}
                    reportID={reportID}
                    reportName={reportName}
                />


            </div>

            <ModalSave 
                reportName={reportName}
                showModal={showModalSave}
                toggleModal={() => setShowModalSave(false)}
                saveReport={saveReport}
            />

        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AnalyticsPagesMatters);