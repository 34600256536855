import ReactSelect from 'components/functional/inputs/ReactSelect';
import { connect } from 'react-redux';

const WorkflowSidebarMarketing = ({ stepToEdit, onSetStepToEdit }) => {

    return (
        <div>

            <h2 className='text-warning'>Marketing</h2>
    
            {/* <ReactSelect 
                formGroup={true}
                title="Set As Unwanted"
                description="Setting this to true will mark the matter as unwanted and will reflect in the marketing dashboard"
                value={stepToEdit.set_as_unwanted}
                onChange={(obj) => onSetStepToEdit('set_as_unwanted', obj.value)}
                options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'YES' },

                ]}
            /> */}

            <ReactSelect 
                formGroup={true}
                title="Mark Case As Closed"
                description="Marking a case as closed will require a user to enter a reason for closing it as well as impact marketing analytics. Only set this to true for unfavorable lead tracking purposes (Negative) and NOT for closing a case out because it is completed / won (Positive)"
                value={stepToEdit.set_as_closed}
                onChange={(obj) => onSetStepToEdit('set_as_closed', obj.value)}
                options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'YES' },

                ]}
            />

        </div>
    )
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarMarketing);