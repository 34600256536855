import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Progress, Row, Nav, NavItem, NavLink } from "reactstrap";

import BarLine from 'components/charts/BarLine';
import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import ApiError from 'components/markup/layout/ApiError';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import moment from 'moment';

import formatText from 'utils/formatText';

const Top3Card = ({obj1, obj2, obj3, prop, title, negative}) => {

    let max = obj1 ? obj1[prop] : 0;
    if(obj3 && obj3[prop] > max) max = obj3[prop]

    return (
        <Card className='custom-shadow-1 border'>
            <CardHeader className='py-4'>
                <h2 className=" mb-0">{title} </h2>
            </CardHeader>
    
            <CardBody>
                <div className="progress-wrapper pt-0">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 ${negative ? 'text-danger' : 'text-success'}`}>
                                {obj1 ? obj1.name : '-'}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{obj1 ? obj1[prop] : 0}</span>
                        </div>
                    </div>
                    <Progress max={max} value={obj1 ? obj1[prop] : 0} color={negative ? 'danger' : 'success'} />
                </div>
                <div className="progress-wrapper pt-0">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 ${negative ? 'text-warning' : 'text-info'}`}>
                                {obj2 ? obj2.name : '-'}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{obj2 ? obj2[prop] : 0}</span>
                        </div>
                    </div>
                    <Progress max={max} value={obj2 ? obj2[prop] : 0} color={negative ? 'warning' : 'info'} />
                </div>
                <div className="progress-wrapper pt-0">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 ${negative ? 'text-yellow' : 'text-purple'}`}>
                                {obj3 ? obj3.name : '-'}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{obj3 ? obj3[prop] : 0}</span>
                        </div>
                    </div>
                    <Progress max={max} value={obj3 ? obj3[prop] : 0} color={negative ? 'yellow' : 'purple'} />
                </div>
        
            </CardBody>
        </Card>
    )
    
}
const MarketingOverview = ({selected_division}) => {
    const location = useLocation()
    const pathname = location.pathname

    const [data, setData] = useState(null)
    const [breakdown, setBreakdown] = useState(null)
    const [err, setErr] = useState(null)
    const fetchData = useCallback(async () => {
        setData(null)
        let type = 
            pathname.includes(30) ? 30 : 
            pathname.includes(60) ? 60 : 
            pathname.includes(90) ? 90 : 
            pathname.includes(180) ? 180 : 
            pathname.includes(365) ? 365 : 
            30;
        
        let date = 
            type === 30 ? parseInt(moment().endOf('day').format('X')) :
            type === 60 ? parseInt(moment().endOf('day').format('X')) :
            type === 90 ? parseInt(moment().endOf('week').format('X')) :
            type === 180 ? parseInt(moment().endOf('month').add(1, 'seconds').format('X')) :
            parseInt(moment().endOf('month').add(1, 'seconds').format('X'))

        const _data = await api.marketing.dashboard({
            division: selected_division._id,
            type: type,
            date: date,
        })

        if(!_data.success) return setErr(_data.message ? _data.message : 'Server Error')

        let hireOpp1;
        let hireOpp2;
        let hireOpp3;
       
        let winOpp1;
        let winOpp2;
        let winOpp3;
      
        let leads1;
        let leads2;
        let leads3;
      
        let opportunities1;
        let opportunities2;
        let opportunities3;

        const hires = JSON.parse(JSON.stringify(_data.data))
            .filter(d => d.opportunities_per_hire)
            .sort((a, b) => a.opportunities_per_hire > b.opportunities_per_hire ? -1 : 1)

        const wins = JSON.parse(JSON.stringify(_data.data))
            .filter(d => d.opportunities_per_win)
            .sort((a, b) => a.opportunities_per_win > b.opportunities_per_win ? -1 : 1)
        
        const leads = JSON.parse(JSON.stringify(_data.data))
            .filter(d => d.leads_total)
            .sort((a, b) => a.leads_total > b.leads_total ? -1 : 1)
       
        const opportunities = JSON.parse(JSON.stringify(_data.data))
            .filter(d => d.opportunities_total)
            .sort((a, b) => a.opportunities_total > b.opportunities_total ? -1 : 1)


        hireOpp1 = hires[0]
        hireOpp2 = hires[1]
        hireOpp3 = hires[2]

        winOpp1 = wins[0]
        winOpp2 = wins[1]
        winOpp3 = wins[2]

        leads1 = leads[0]
        leads2 = leads[1]
        leads3 = leads[2]
        
        opportunities1 = opportunities[0]
        opportunities2 = opportunities[1]
        opportunities3 = opportunities[2]


        setData(_data.data)
        setBreakdown({
            hireOpp1,
            hireOpp2,
            hireOpp3,

            winOpp1,
            winOpp2,
            winOpp3,

            leads1,
            leads2,
            leads3,

            opportunities1,
            opportunities2,
            opportunities3,
        })
    }, [pathname, selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError error={err} />
    if(!data) return <div className='py-6'><Circle /></div>

    const sourcesWithLeads = data.filter(d => d.leads_total)

    return (

        <>

        <Nav
            className="nav-fill flex-column flex-sm-row"
            id="tabs-text"
            pills
            role="tablist"
        >

            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${pathname === '/marketing/breakdown_30' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/marketing/breakdown_30"
                >
                    Last 30 Days
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${ pathname === '/marketing/breakdown_90' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/marketing/breakdown_90"
                >
                    Last 90 Days
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${ pathname === '/marketing/breakdown_180' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/marketing/breakdown_180"
                >
                    Last 180 Days
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={`mb-sm-3 mb-md-0 ${ pathname === '/marketing/breakdown_365' ? 'active' : ''}`}
                    role={Link}
                    tag={Link}
                    to="/marketing/breakdown_365"
                >
                    Last 365 Days
                </NavLink>
            </NavItem>

        </Nav>

        <BarLine 
            type="bar"
            title={`Total Leads By Source`}
            labels={['Total Leads']}
            datasets={sourcesWithLeads.map(d => { return { label: d.name, data: [d.leads_total] } })}
        />

        <Row>
            <Col md={3}>
                <Top3Card 
                    title="Largest Opportunity / Win"
                    prop="opportunities_per_win"
                    obj1={breakdown.winOpp1}
                    obj2={breakdown.winOpp2}
                    obj3={breakdown.winOpp3}
                    negative={true}
                />
            </Col>
            <Col md={3}>
                <Top3Card 
                    title="Largest Opportunity / Hire"
                    prop="opportunities_per_hire"
                    obj1={breakdown.hireOpp1}
                    obj2={breakdown.hireOpp2}
                    obj3={breakdown.hireOpp3}
                    negative={true}
                />
            </Col>
            <Col md={3}>
                <Top3Card 
                    title="Best Sources By Lead Volume"
                    prop="leads_total"
                    obj1={breakdown.leads1}
                    obj2={breakdown.leads2}
                    obj3={breakdown.leads3}
                />
            </Col>
            <Col md={3}>
                <Top3Card 
                    title="Best Sources By Opportunity Volume"
                    prop="opportunities_total"
                    obj1={breakdown.opportunities1}
                    obj2={breakdown.opportunities2}
                    obj3={breakdown.opportunities3}
                />
            </Col>
        </Row>

        <Card>
            <div className='table-responsive'>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Source</th>
                            <th>Leads</th>
                            <th>Unique Leads</th>
                            <th>Duplicate Leads</th>
                            <th>Opportunities</th>
                            <th>Potentials</th>
                            <th>Hires</th>
                            <th>Won</th>
                            <th>DNQ</th>
                            <th>No Hires</th>
                            <th>Refer Out</th>
                            <th>AVG Time Till Hired</th>
                            <th>AVG Time Till Won</th>
                            <th>Opp / Hire</th>
                            <th>Opp / Win</th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((d, i) => (
                            <tr key={d.lead_source}>
                                <td><A href={`/lead_sources/${d.lead_source}`}>{d.name}</A></td>
                                <td>{d.leads_total}</td>
                                <td>{d.leads_unique}</td>
                                <td>{d.leads_duplicate}</td>
                                <td>{d.opportunities_total}</td>
                                <td>{d.opportunities_potential}</td>
                                <td>{d.opportunities_hired}</td>
                                <td>{d.opportunities_won}</td>
                                <td>{d.opportunities_dnq}</td>
                                <td>{d.opportunities_no_hire}</td>
                                <td>{d.opportunities_refer_out}</td>
                                <td>{formatText(d.avg_time_till_hired).secondsToTime()}</td>
                                <td>{formatText(d.avg_time_till_won).secondsToTime()}</td>
                                <td>{d.opportunities_per_hire}</td>
                                <td>{d.opportunities_per_win}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
      
    </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MarketingOverview);