import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

const WorkflowSidebarMonitoringInterval = ({ stepToEdit, setUnsavedChanges, onSetStepToEdit }) => {

    const [trust, setTrust] = useState(stepToEdit.increment_trust)

    const onChange = useCallback((val) => {

        setTrust(val)

        let num = parseFloat(val)
        if(Number.isNaN(num)) num = 0
       
        onSetStepToEdit('increment_trust', num);
        setUnsavedChanges(true)

    }, [onSetStepToEdit, setUnsavedChanges])

    return (
        <div>
    
            <h2 className='text-warning'>Billing</h2>
    
            <FormGroup>
                <label className='form-control-label'>Increment Trust</label>
                <p className='text-sm mb-0'>If set, moving to this step will add the value below into the trust value for a matter. All payments charged will first be processed to this account.</p>
                <Input
                    value={trust}
                    onChange={(e) => onChange(e.target.value)}
                /> 
            </FormGroup>

        </div>
    )
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarMonitoringInterval);