import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, CardHeader, Input } from "reactstrap";
import { Link } from 'react-router-dom';

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'

import LeadSourceCategoryEdit from '../components/Edit'
import { toggleStandardLoader } from "store/functions/system/system";
import LoadingTable from 'components/markup/loading/Table';

import ApiError from "components/markup/layout/ApiError";
import { toast } from 'react-toastify'

const MarketingLeadSourceCategories = ({setTab, selected_division}) => {
    const [ tableData, setTableData ] = useState(null)
    const [ toDelete, setToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ search, setSearch ] = useState('');

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(() => new Promise (async resolve => {
        const query = await api.lead_source_categories.find(selected_division._id)
        
        if(query.data) {
            return setTableData(query.data)
        } else {
            setError(query.message)
        }
    }), [selected_division._id])

    const onDelete = useCallback(async () => {
        toggleStandardLoader(true)
        const deleted = await api.lead_source_categories.delete(toDelete._id);
        toggleStandardLoader(false)

        if(deleted.success) return fetchData();
        toast.error(`Something's not right, please try again.`)
    }, [fetchData, toDelete])

    const shouldRender = useCallback((category) => {
        if(!search) return true;
        
        const s = search.toLowerCase().trim();
        const n = category.name.toLowerCase().trim();
        if(n.includes(s)) return true;
        if(n === s) return true;

        return false;
    }, [search])


    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(error) return <ApiError err={error} />

    const results = search && tableData ? tableData.filter(category => shouldRender(category)) : tableData

    return (

        <>

            <TextButtonRow 
                title={"All Lead Source Categories"}
                button={(
                    <div>
                        <Link to="/marketing/lead_sources" className="btn btn-info"><i className="fas fa-arrow-left ml-2" /> All Lead Sources</Link>
                        <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Source Category</button>
                    </div>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <CardHeader className="pl-2 pr-4 pb pt-3 pb-3 ml-1">
                    <Input 
                        className="ml-1"
                        style={{width: 190, maxWidth: '100'}}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardHeader>
                
                {!results ?  <LoadingTable /> : (
                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Source Category</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {results.length ? (
                                    results.map(category => (
                                        <tr key={category._id}>
                                            <td>
                                                <ModalToggler component={LeadSourceCategoryEdit} onSaved={fetchData} _id={category._id}>
                                                    <span className="cursor-pointer">{category.name}</span>
                                                </ModalToggler>
                                            </td>

                                            <td className="text-right">
                                                <TableIcons
                                                    icons={[
                                                        { 
                                                            icon: 'fas fa-edit',
                                                            color: 'success', 
                                                            wrapper: ModalToggler, 
                                                            component: LeadSourceCategoryEdit,
                                                            onSaved: fetchData,
                                                            _id: category._id
                                                        },
                                                        {  
                                                            icon: 'fas fa-trash', 
                                                            color: 'danger',
                                                            onClick: () => setToDelete(category)
                                                        },
                                                    ]}
                                                />

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No Location Types Found</td>
                                        <td></td>   
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                )}

            </Card>

            <ConfirmationModal 
                showModal={toDelete ? true : false}
                toggleModal={() => setToDelete(null)}
                title="Delete Source Category"
                body={(
                    <span>
                        Are you sure you wish to delete the source category {toDelete ? toDelete.name : ''}? This will prevent any associations from being attached to it in the future.
                    </span>
                )}
                onConfirmation={onDelete}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MarketingLeadSourceCategories);